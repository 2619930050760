import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the Comma2brPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'comma2br',
})
export class Comma2brPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {
    let result;
    if (value) {
      result = value.replace(/,/mg, '<br>');
    }

    return result ? result : value;
  }
}
