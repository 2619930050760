import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import * as Config from '../config';


// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class AuthenticationService {

  user: any;
  headers = new HttpHeaders();

  constructor(
    public http: HttpClient,
    public storage: Storage,

  ) {  }

  public logOut() {
    return this.storage.remove('User');
  }

  doLogin(username, password) {
    return this.http.post(Config.WORDPRESS_URL + 'wp-json/jwt-auth/v1/token', {username, password});
  }


  validateAuthToken(token): any {

    this.headers = this.headers.set(
      'Content-Type',  'application/json'
    ) ;

    this.headers = this.headers.set(
      'Authorization:',  'Basic ' + token
    ) ;

    const httpOptions = {
      headers: this.headers
    };

    return this.http.post(Config.WORDPRESS_URL + 'wp-json/simple-jwt-authentication/v1/token/validate?token=' + token, {}, httpOptions );
  }
}

