import { provideAuth } from '@angular/fire/auth';
import { AdmobConsentDefinitions } from './../../node_modules/@capacitor-community/admob/dist/esm/consent/consent-definition.interface.d';
import { WpProvider } from './providers/wp';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { Storage } from '@ionic/storage-angular';
import { UserData } from './providers/user-data';
import { AppDataProvider } from './providers/app-data';
import { AdMob,AdMobRewardItem,AdOptions,BannerAdOptions,BannerAdPosition,BannerAdSize,RewardAdOptions,RewardAdPluginEvents,AdmobConsentStatus } from '@capacitor-community/admob';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  loggedIn = false;
  public dark = false;
  currentURL = '';

  testing = false;

  constructor(
    private wp: WpProvider,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private appData: AppDataProvider,

  ) {
    this.dark = false;
    this.initializeApp();
  }

  async ngOnInit() {

    await this.storage.create();

    this.checkLoginStatus();
    this.listenForLoginEvents();

    this.currentURL = window.location.href;
    const Urls = this.currentURL.split('/');
    console.log(Urls);

    if (Urls.length >= 5) {
      const page = Urls[4];
      if (page === 'register') {
        const parm = Urls[5];
      }
    }
    if (!this.platform.is('desktop')) {
      this.initNotification()    
    }

  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        StatusBar.hide();
        SplashScreen.hide();
      }

      this.userData.getUsername().then ((req) => {
        this.appData.userData = req;
      });

      this.currentURL = window.location.href;
      console.log(this.currentURL);

      const page = this.currentURL.split('/').pop();
      console.log(page);

      const currentUrl = window.location.href;
      console.log(currentUrl);
      console.log(currentUrl.includes('aba-connect.ca'));

      if (currentUrl.includes('aba-connect.ca')) {
        this.testing = false;
      } else {
        this.testing = true;
      }
    });

    this.initAdMob();
    // this.showBanner();

  }

  async initAdMob() {

  await AdMob.initialize();
 
  const [trackingInfo, consentInfo] = await Promise.all([
   AdMob.trackingAuthorizationStatus(),
   AdMob.requestConsentInfo(),
  ]);
 
  if (trackingInfo.status === 'notDetermined') {
   /**
    * If you want to explain TrackingAuthorization before showing the iOS dialog,
    * you can show the modal here.
    * ex)
    * const modal = await this.modalCtrl.create({
    *   component: RequestTrackingPage,
    * });
    * await modal.present();
    * await modal.onDidDismiss();  // Wait for close modal
    **/
 
   await AdMob.requestTrackingAuthorization();
  }

  const authorizationStatus = await AdMob.trackingAuthorizationStatus();

  if (
          authorizationStatus.status === 'authorized' &&
          consentInfo.isConsentFormAvailable &&
          consentInfo.status === AdmobConsentStatus.REQUIRED
  ) {
   await AdMob.showConsentForm();
  }

  }

  async showBanner() {

    const options: BannerAdOptions = {
      // adId: 'ca-app-pub-5297016832074468/4860577188',
      adId: 'ca-app-pub-3940256099942544/9214589741',

      isTesting: true,
      // adSize: BannerAdSize.BANNER, 
      adSize: BannerAdSize.SMART_BANNER,
      position: BannerAdPosition.BOTTOM_CENTER
    }
    await AdMob.showBanner( options);
  }

  darkModeToggle(ev) {
    console.log(this.dark);
    this.toggleDarkPalette(ev.detail.checked);

    const user = this.userData.username;
    console.log('User:', user);
    this.wp.setUserMeta(user, 'dark', this.dark).subscribe ((req) => {
      console.log(req);

      this.wp.getUserMeta(user, 'dark').subscribe ((req1) => {
        console.log(req1);
        this.menu.close();
      });
    });
  }

  toggleDarkPalette(shouldAdd) {
    document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  exit() {

    if (this.platform.is('hybrid')) {
      navigator['app'].exitApp();
    }
    
    const currentUrl = window.location.href;
    console.log(currentUrl);
    console.log(currentUrl.includes('aba-connect.ca'));

    if (currentUrl.includes('localhost:8100'))  {
      window.top.location.replace('http://localhost:8100');

    } else if (currentUrl.includes('server.poznikoff.com:8000')) {
      window.top.location.replace('http://server.poznikoff.com:8000');

    } else if (currentUrl.includes('localhost:8000')) {
      window.top.location.replace('http://localhost:8000');

    } else if (currentUrl.includes('dev.aba-connect.ca')) {
      window.top.location.replace('https://dev.aba-connect.ca');

    } else if (currentUrl.includes('www.aba-connect.ca')) {
      window.top.location.replace('https://aba-connect.ca');
    }

  }

  logout() {

    document.documentElement.classList.toggle('ion-palette-dark', false);
    
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

  goToRegister() {
    this.appData.navForward('/register', { role: 'All' } );
  }

  initNotification() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.log('Error: notificationspermissions not granted');
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }
}

