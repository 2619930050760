/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, forwardRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

export function createCounterRangeValidator(maxValue, minValue) {
  return (c: UntypedFormControl) => {
    let err = {
      rangeError: {
        given: c.value,
        max: maxValue || 10,
        min: minValue || 0
      }
    };
    return (c.value > +maxValue || c.value < +minValue) ? err : null;
  }
}

@Component({
  selector: 'counter-input',
  templateUrl: './counter-input.component.html',
  styleUrls: ['./counter-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CounterInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CounterInputComponent), multi: true }
  ]  
})
export class CounterInputComponent implements ControlValueAccessor, OnChanges {

  propagateChange: any = () => { };
  validateFn: any = () => { };

  @Input() _counterValue = 1;
  @Input() counterRangeMax = 10;
  @Input() counterRangeMin = 1;
  @Input() label;
  @Input() counterIncrement: number = 1;

  get blocks() {
    return '&#9609; '.repeat(this.counterValue / this.counterIncrement);
  }

  get counterValue() {
    return this._counterValue;
  }

  set counterValue(val) {
    this._counterValue = val;
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {
    if (inputs.counterRangeMax || inputs.counterRangeMin) {
      this.validateFn = createCounterRangeValidator(this.counterRangeMax, this.counterRangeMin);
      this.propagateChange(this.counterValue);
    }
  }

  writeValue(value) {
    if (value) {
      this.counterValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  increase() {
    if (this.counterValue < this.counterRangeMax) this.counterValue = this.counterValue + this.counterIncrement;
  }

  decrease() {
    if (this.counterValue > this.counterRangeMin) this.counterValue = this.counterValue - this.counterIncrement;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
