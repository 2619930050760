import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppDataProvider } from '../../providers/app-data';
import { modalController } from '@ionic/core';

@Component({
  selector: 'app-popover-calendar',
  templateUrl: './popover-calendar.page.html',
  styleUrls: ['./popover-calendar.page.scss'],
})
export class PopoverCalendarPage implements OnInit {

  provider: any;
  job: any;
  matchData: any;

  constructor(
    public route: ActivatedRoute,
    public appData: AppDataProvider,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    console.log('Job:', this.job);
    console.log('Provider:', this.provider);
    console.log('matchData:', this.matchData);
  }

  async closeModal() {
    const close = 'Modal Removed';
    await this.modalCtrl.dismiss(close);
  }

}
