import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaRemove'
})
export class CommaRemovePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let result;
    if (value) {
      const array = value.toString();
      result = array.replace(/,/g, ' ');
    }

    return result ? result : value;
  }

}
