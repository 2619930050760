/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LoadingController, ToastController } from '@ionic/angular';
import { NgModel } from '@angular/forms';

import { AuthenticationService } from '../../providers/authentication/authentication.service';
import { AppDataProvider } from '../../providers/app-data';
import { WpProvider } from '../../providers/wp';
import { Router, ActivatedRoute } from '@angular/router';

// import { ViewProviderPage } from '../../pages/view-provider/view-provider';
// import { ViewResumePage } from '../../pages/view-resume/view-resume';
import { WORDPRESS_URL } from '../../providers/config';
@Component({
  selector: 'providers-matched',
  templateUrl: './providers-matched.component.html',
  styleUrls: ['./providers-matched.component.css']
})
export class ProvidersMatchedComponent implements OnInit {
  @Input() postId: any;
  @Input() job: any;

  loggedUser: any;
  username: any;
  profile: any;
  haveProfile: boolean = false;

  displayName: string = '';
  providers: any = [];
  user: any;

  postIDs: any = [];
  matched: any;

  p: any;

  ts: string = '?ts=' + new Date().getTime();
  avatarURL: string = WORDPRESS_URL + 'wp-content/uploads/aba-images/';
  showSummary: any = [];
  log: any;

  loading = false;
  loadingCTRL: any;
  params: any;
  constructor(
    public appData: AppDataProvider,
    public wp: WpProvider,
    public authenticationService: AuthenticationService,
    public tc: ToastController,
    public loadingCtrl: LoadingController,
    public route: ActivatedRoute,
    public router: Router,
  ) {
      console.log('Hello ProviderProfilesMatchedComponent Component');

   }

  ngOnInit(): void {
    console.log('ProvidersMatchedComponent');
    this.init();
  }

  init() {

    console.log('PostId:', this.postId);

    this.user = this.appData.getUser();
    if (this.user) {
      this.loggedUser = true;
      this.username = this.user.username;

      this.loading = true;

      console.log('Job', this.job);
      // this.username = 'Family5'; this.postId = 928;
      this.wp.getCompanyProvidersRanked(this.username, this.postId)
        .subscribe(
          (res: any) => {
            this.loading = false;
            if (res.status === 'success') {
              this.providers = res.providers;
              this.postIDs = res.post_ids;
              this.log = res.log;
              this.haveProfile = true;
              console.log('Providers:', this.providers);


            } else {
              this.haveProfile = false;
            }
          });
    }
  }

  isNumber(val: any) {
    return typeof val === 'number';
  }

  toggleSummary(i: number) {
    console.log('index:', i);
    console.log('showSummary[i]:', this.showSummary[i]);

    if (!this.showSummary[i] ) {
      this.showSummary[i] = true;
    } else {
      this.showSummary[i] = false;
    }
  }

  viewProvider(id: any) {
    this.appData.navForward('/company-dashboard/view-provider',
      { id, provider: this.providers[id], postId: this.providers[id].id, job: this.job });

    // this.navCtrl.push(ViewProviderPage, { id: id, provider: this.providers[id], postId: this.providers[id].id, job: this.job });
  }

  viewResume(id: any) {
    this.appData.navForward('/company-dashboard/view-resume',
      { provider: this.providers[id]});
    // this.navCtrl.push(ViewResumePage, { id: id, provider: this.providers[id] });
  }

}
