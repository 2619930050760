
@if (readOnly === false) {
  <div #acc class='cal' FormArrayName="schedule">
    <ion-grid [fixed]="true" class='ion-no-margin ion-no-padding cal-grid'>
      <ion-row class='ion-no-padding'>
        <ion-col size="1" class='cal-time ion-no-padding ion-text-start'>
          <ion-button size="small" fill="clear" color="primary"  expand="full" class='btn-time' (click) = "selectReset()">
            Reset<br>
            <ion-icon name="refresh-outline"></ion-icon>
          </ion-button>
        </ion-col>
        @for (weekday of weekDays; track weekday; let i = $index) {
          <ion-col class='cal-day ion-no-padding ion-text-start' >
            <ion-button size="small" color="primary" expand="full" (click)="selectDay(i)" class='btn-day ion-no-padding'>
              {{weekday.day}}<br>
              <ion-icon class='icon-day' name='arrow-down'></ion-icon>
            </ion-button>
          </ion-col>
        }
      </ion-row>
      @for (t of schedule.controls; track t; let i = $index) {
        <ion-row   FormGroupName="i"  >
          <ion-col size="1" class='cal-time ion-no-margin ion-text-start'>
            <ion-button size="small" fill="clear" color="primary" expand="full" class='btn-time' (click)="selectTime(i)">
              {{dayTimes[i].time}}
              <ion-icon class='icon-time' name='arrow-forward' ></ion-icon>
            </ion-button>
          </ion-col>
          @for (d of schedule.controls[i]['controls']['days'].controls; track d; let j = $index) {
            <ion-col class='cal-day ion-no-margin ion-text-center' >
              <ion-toggle id="ac-{{i}}-{{j}}" class="ion-margin-top ion-margin-bottom"
                (ionChange)="toggleAvailability(schedule.controls[i]['controls']['days'],j)"
                [checked]="d.value" >
              </ion-toggle>
            </ion-col>
          }
        </ion-row>
      }
    </ion-grid>
  </div>
}

@if (readOnly === true) {
  <div #acc class='cal-ro'>
    <ion-grid [fixed]="true"  class='ion-no-margin ion-no-padding cal-ro' >
      <ion-row class="cal-weekdays-ro">
        <ion-col size="2" class='cal-time-ro ion-no-padding'>
        </ion-col>
        @for (weekday of weekDays; track weekday) {
          <ion-col class='cal-day-ro ion-no-padding'>
            {{weekday.day}}
          </ion-col>
        }
      </ion-row>
      @for (dayTime of dayTimes; track dayTime; let i = $index) {
        <ion-row class='cal-weekdays-ro '>
          <ion-col size="2" class='cal-time-ro ion-no-padding ion-text-center'>
            {{dayTime.time}}
          </ion-col>
          @for (weekday of weekDays; track weekday; let j = $index) {
            <ion-col class='cal-day-ro'>
              @if (jobSchedule) {
                <div >
                  @if (!isNull(jobSchedule[i]) && !isNull(schedule[i])) {
                    <div>
                      @if (jobSchedule[i].days[j] ) {
                        <div>
                          @if (schedule[i].days[j]) {
                            <ion-icon name='checkmark-circle'></ion-icon>
                          }
                          @if (!schedule[i].days[j]) {
                            <ion-icon color="danger" name='close-circle'></ion-icon>
                          }
                        </div>
                      }
                      @if (!jobSchedule[i].days[j] ) {
                        <div>
                          @if (schedule[i].days[j] === true) {
                            <ion-icon name='checkmark' ></ion-icon>
                          }
                          @if (schedule[i].days[j] === false) {
                            <ion-icon name='remove'></ion-icon>
                          }
                        </div>
                      }
                    </div>
                  }
                  @if (isNull(jobSchedule[i]) || isNull(schedule[i])) {
                    <div>
                      <ion-icon name='remove'></ion-icon>
                    </div>
                  }
                </div>
              }
              @if (!jobSchedule) {
                <div class='cal-time-ro'>
                  @if (!isNull(schedule[i])) {
                    <div>
                      @if (schedule[i].days[j] === true) {
                        <ion-icon name='checkmark-circle'></ion-icon>
                      }
                      @if (schedule[i].days[j] === false) {
                        <ion-icon name='remove'></ion-icon>
                      }
                    </div>
                  }
                </div>
              }
            </ion-col>
          }
        </ion-row>
      }
    </ion-grid>
    @if (jobSchedule) {
      <div class='legend-ro'>
        <ion-icon name='checkmark-circle' ></ion-icon> Job Schedule Matched to Provider<br>
        <ion-icon color="danger" name='close-circle' ></ion-icon> Job Schedule NOT Matched to Provider<br>
        <ion-icon name='checkmark' ></ion-icon> Provider is Available<br>
        <ion-icon name='remove'></ion-icon> Provider is NOT Available<br>
      </div>
    }
    @if (!jobSchedule) {
      <div class='legend'>
      </div>
    }
  </div>
}
