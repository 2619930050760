import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
//import { BrowserModule } from '@angular/platform-browser';

import { AccountComponent } from '../components/account/account.component';
import { JobsComponent } from '../components/jobs/jobs.component';
import { JobFieldsComponent } from '../components/job-fields/job-fields.component';
import { ProviderProfilesComponent } from '../components/provider-profiles/provider-profiles.component';
import { CounterComponent } from '../components/counter/counter.component';
import { FormFieldComponent } from '../components/form-field/form-field.component';
import { CounterInputComponent } from '../components/counter-input/counter-input.component';
import { ProfileMatchFieldsComponent } from './profile-match-fields/profile-match-fields';
import { AvailabilityCalendarComponent } from './availability-calendar/availability-calendar';
import { AvailabilityCalendarDaysComponent } from './availability-calendar-days/availability-calendar-days';
import { JobsMatchedComponent } from './jobs-matched/jobs-matched';
import { UploadResumeComponent } from './upload-resume/upload-resume';
import { ImageCaptureComponent } from './image-capture/image-capture';
import { AvatarComponent } from './avatar/avatar.component';


import { PdfViewerModule } from 'ng2-pdf-viewer';

import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PopOverPageModule } from '../pages/pop-over/pop-over.module';
import { ProvidersMatchedComponent } from './providers-matched/providers-matched.component';
import {  MaskitoDirective, MaskitoPipe} from '@maskito/angular';
import { FooterComponent } from './footer/footer.component';


@NgModule({
	declarations: [
		JobFieldsComponent,
		FormFieldComponent,
		ProviderProfilesComponent,
		JobsComponent,
		AccountComponent,
		CounterComponent,
		CounterInputComponent,
		ProfileMatchFieldsComponent,
		AvailabilityCalendarComponent,
		AvailabilityCalendarDaysComponent,
		JobsMatchedComponent,
		UploadResumeComponent,
		ImageCaptureComponent,
		AvatarComponent,
	    ProvidersMatchedComponent,
     	FooterComponent,

	],
	imports: [
		IonicModule,
		CommonModule,
		//BrowserModule,
		PipesModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		PopOverPageModule,
		MaskitoDirective, MaskitoPipe,

	],
	exports: [
		JobFieldsComponent,
		FormFieldComponent,
		ProviderProfilesComponent,
		JobsComponent,
		AccountComponent,
		CounterComponent,
		CounterInputComponent,
		ProfileMatchFieldsComponent,
		AvailabilityCalendarComponent,
		AvailabilityCalendarDaysComponent,
		JobsMatchedComponent,
		UploadResumeComponent,
		ImageCaptureComponent,
		AvatarComponent,
	    ProvidersMatchedComponent,
		FooterComponent,


		PdfViewerModule

	]
})
export class ComponentsModule {}
