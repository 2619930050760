/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'job-fields',
  templateUrl: './job-fields.component.html',
  styleUrls: ['./job-fields.component.scss']
})
export class JobFieldsComponent implements OnInit {
  @Input() f: any ;
  @Input() j: string | undefined;

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // console.log('f:', this.f);
    // console.log('j:', this.j);
  }

}
