<ion-app [class.dark-theme]="dark" >
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">
      <ion-content>

        @if (loggedIn) {
          <ion-list lines="none">
            <ion-list-header>
              Account
            </ion-list-header>
            <ion-menu-toggle autoHide="false">
              <ion-item button (click)="logout()" detail="false">
                <ion-icon slot="start" name="log-out"></ion-icon>
                <ion-label>
                  Logout
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-item>
              <ion-icon slot="start" name="moon-outline"></ion-icon>
              <ion-label>
                Dark Mode
              </ion-label>
              <ion-toggle [(ngModel)]="dark" (ionChange)="darkModeToggle($event)"></ion-toggle>
            </ion-item>
            <ion-menu-toggle autoHide="false">
              <ion-item button (click)="exit()" detail="false">
                <ion-icon slot="start" name="exit"></ion-icon>
                <ion-label>
                  Exit App
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        }

        @if (!loggedIn) {
          <ion-list lines="none">
            <ion-list-header>
              Account
            </ion-list-header>
            <ion-menu-toggle autoHide="false">
              <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
                <ion-icon slot="start" name="log-in"></ion-icon>
                <ion-label>
                  Login
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle autoHide="false">
              <ion-item button (click)="exit()" detail="false">
                <ion-icon slot="start" name="exit"></ion-icon>
                <ion-label>
                  Exit App
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        }



      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>
