/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { AppDataProvider } from '../../providers/app-data';
import { UntypedFormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'availability-calendar',
  templateUrl: 'availability-calendar.html',
  styleUrls: ['availability-calendar.scss']
})

export class AvailabilityCalendarComponent {
  @Input() schedule!: UntypedFormArray;
  @Input() jobSchedule!: UntypedFormArray;

  @Input() title!: string;
  @Input() readOnly = false;

  weekDays: any;
  dayTimes: any;
  constructor(
    public appData: AppDataProvider ,
  ) {
    this.weekDays = appData.weekDays;
    this.dayTimes = appData.dayTimes;

    console.log('Hello AvailabilityCalendarComponent Component');
  }

  public isNull(x: any) {
    if (typeof x === 'undefined') {
      return true;
    }
    if (x == null) {
      return true;
    }

    if (x === null) {
      return true;
    }
    return false;
  }

  public log(o: any ){
    console.log(o);
  }

  public toggleAvailability(f: UntypedFormArray, j: string | number) {
    if (f.controls[j].value === false) {
      f.controls[j].setValue(true);
    } else {
      f.controls[j].setValue(false);
    }
  }

  selectReset() {
    Object.keys(this.schedule.controls).forEach((key: string) => {
      const days =  this.schedule.controls[key].days as UntypedFormArray;
      Object.keys(days.controls).forEach( (key2: string, idx) => {
        days.controls[key2].setValue(false);
      });
    });

  }

  selectDay(i: any) {
    this.schedule.controls.forEach( (control: any, idx) => {
      if (idx >= 1 && idx <= 12) {
        this.toggleAvailability(control.controls.days, i);
      }
    });
  }

  selectTime(j: string | number) {
    const week = this.schedule.controls[j] as UntypedFormArray;
    const dayKey = 'days';
    const days = week.controls[dayKey];

    days.controls.forEach((control: { value: boolean; setValue: (arg0: boolean) => void; }, idx: number) => {
      if (idx !== 0 && idx !== 6) {
        if (control.value === false) {
          control.setValue(true);
        } else {
          control.setValue(false);
        }
      }
    });
  }

  public getDays() {
    return this.weekDays;
  }

  public getTimes() {
    return this.dayTimes;
  }



}
