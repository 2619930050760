/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'image-capture',
  templateUrl: 'image-capture.html',
  styleUrls: ['./image-capture.scss']
})
export class ImageCaptureComponent implements OnInit {

  @Output() cancel = new EventEmitter();
  @Output() gotPhoto = new EventEmitter<any>();

  @ViewChild('video', { static: true })
  public video: ElementRef;

  @ViewChild('canvas', { static: true })
  public canvas: ElementRef;

  public captures: Array<any>;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit()
  {
    
   }

  // tslint:disable-next-line: use-lifecycle-interface
  public ngAfterViewInit() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then( stream => {
          console.log(stream);
          // this.video.nativeElement.src = window.URL.createObjectURL(stream);
          // this.video.nativeElement.play();
        });
    }
  }

  public capture() {
    const context = this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 320, 180);
    this.captures.push(this.canvas.nativeElement.toDataURL('image/jpeg'));
  }

  public cancelCapture() {
    this.cancel.emit();
  }

  public photoSelected(i: number) {
    this.gotPhoto.emit(this.captures[i]);
  }

  constructor() {
    console.log('Hello ImageCaptureComponent Component');
    this.captures = [];
  }

}
