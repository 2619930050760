/* eslint-disable @angular-eslint/component-selector */
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { WpProvider } from '../../providers/wp';
import { AuthenticationService } from '../../providers/authentication/authentication.service';
import { AppDataProvider } from '../../providers/app-data';

@Component({
  selector: 'upload-resume',
  templateUrl: 'upload-resume.html'
})
export class UploadResumeComponent {

  form: UntypedFormGroup;
  loading = false;
  user: any;
  username: any;

  resumeFile: string;

  notPDF = false;

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    public wp: WpProvider,
    public authenticationService: AuthenticationService,
    public appData: AppDataProvider,
  ) {

    this.user = this.appData.getUser();
    if (this.user) {
      this.username = this.user.username;
      this.createForm();
    }

    console.log('Hello UploadResumeComponent Component');

  }

  createForm() {
    this.form = this.fb.group({
      user: new UntypedFormControl(this.username),
      resume: null
    });
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file.type);
      if (file.type !== 'application/pdf') {
        this.notPDF = true;
        this.form.get('resume')?.setValue(null);
        this.resumeFile = '';
        return;
      } else {
        this.notPDF = false;
      }
      this.resumeFile = file.name;

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get('resume')?.setValue({
          filename: file.name,
          filetype: file.type,
          value: (reader.result as string).split(',')[1]
        });
      };
    }
  }

  onSubmit() {
    const formModel = this.form.value;
    this.loading = true;
    this.wp.saveProviderResume(formModel)
    .subscribe(
      res => {
        this.loading = false;
        console.log('Upload res:', res);
        this.resumeFile = '';
      }
    );
  }

  clearFile() {
    this.form.get('resume')?.setValue(null);
    this.resumeFile = '';

    this.loading = false;
  }

}
