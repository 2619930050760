/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Component({
  selector: 'counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  @Input() formControlName: FormControlName;

  constructor() {
    console.log('Hello CounterComponent Component');

  }

  count: any;

  updateCount(adjustment = 1) {
    this.formControlName.control.setValue = this.formControlName.control.value + adjustment;
  }

  get blocks() {
    return '&#9609;'.repeat(this.formControlName.control.value);
  }


  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    
  }

}
