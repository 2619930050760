  <div *ngFor="let j of jobs; let i = index;" class="ion-no-padding  job-item" [ngClass]="{'inactive': showMode === 'showCompanyJobs' && (i+1 > jobsAvailable)} " >

    <div class='inactive-header' *ngIf=" showMode === 'showCompanyJobs' && (i+1 > jobsAvailable) ">
      Insufficient Subscriptions for Job
    </div>

    <div *ngIf="showMode === 'showCompanyJobs'"  class='ion-no-padding'>

      <div class='ion-float-left job-item' >
        <div class='job-list-name ion-no-padding' style='margin-bottom: 10px;'>
          <img class='child-icon ion-float-left' src="../assets/child.png">
          <div class='job-name ion-padding-left ion-padding-top' >&nbsp; {{ j.jobName }}</div>
        </div>




            <ion-button size="small"  (click)="editJob(i)" [ngClass]="{'brighten-button': (i+1 > jobsAvailable)} " >
              Edit Position &nbsp;
              <ion-icon name="create" class='ion-float-right'></ion-icon>
            </ion-button>
  
            <ion-button size='full' (click)="toggleDetails(i)" >
              <b>Best-Fit</b> &nbsp; Providers &nbsp;
              <ion-icon name="caret-down-outline" *ngIf="showDetails[i]" slot='end'></ion-icon>
              <ion-icon name="caret-forward-outline" *ngIf="!showDetails[i]" slot='end'></ion-icon>
            </ion-button>



      </div>

      <div *ngIf="showDetails[i]" >
          <providers-matched [postId]="jobIds[i]" [job]="j"></providers-matched> 
      </div>

    </div>

    <ion-row  class='ion-no-padding border-bot'*ngIf="showMode==='showAllJobs'" >
      <ion-col col-8 col-sm-9 col-lg-10>
        <div class='name border-bot'>

          <div id="image" style="display:inline;">
            <img src="../assets/child.jpg">
          </div>
          <div id="texts" style="display:inline; white-space:nowrap;">
            {{j.jobName}}
          </div>
        </div>

        <div *ngFor="let f of j | keyvalue; let ii = index" class='ion-float-left' margin-top=30>
          {{f[ii]}} {{f.value}} {{jobFields[j.jobGroup][f[ii]]}}
          <div *ngIf="isObject(jobFields[j.jobGroup][f[ii]]) && jobFields[j.jobGroup][f[ii]].heading && f.value" class='job-list-item'>

            <span class="heading">{{ jobFields[j.jobGroup][f[ii]].label }}:</span>
            <span class='{{jobFields[j.jobGroup][f[ii]].type}}' class='ion-text-wrap'>{{f.value | commasAddSpace}}</span>

          </div>

        </div>

        <div *ngFor="let f of j | keyvalue; let ii=index;" class='ion-float-left'>
          <div *ngIf="isObject(jobFields[j.jobGroup][f[ii]]) && jobFields[j.jobGroup][f[ii]].summary" class='job-list-item'>
            <span class="heading">{{ jobFields[j.jobGroup][f[ii]].label }}:</span>
            <span class='{{jobFields[j.jobGroup][f[ii]].type}}' class='ion-text-wrap'>{{f.value | commasAddSpace}}</span>
          </div>
        </div>

      </ion-col>

      <ion-col >
        <ion-button small round class='ion-float-right' (click)="viewJob(i)">
          Job Details &nbsp;
          <ion-icon name="arrow-right" class='ion-float-right'></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

  </div>

  <ion-item *ngIf="jobs.length === 0 && !loading && showMode === 'showCompanyJobs'" class='ion-text-wrap ion-padding'>
    <ion-button full (click)="doNewJob();" class='job-button-large'>
      <ion-icon name="add"></ion-icon>Add New Job
    </ion-button>
  </ion-item>
  <ion-item *ngIf="jobs.length === 0 && !loading && showMode !== 'showCompanyJobs'" class='ion-text-wrap ion-padding'>
    No "{{appData.jobGroup}}" jobs are currently available.
  </ion-item>


