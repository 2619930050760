<ion-item>
  <ion-avatar class='ion-float-left' (click)="cameraClick()">
    <div class='avatar'>
      <img id='avatar' class='landscape' src="{{avatarURL}}">
    </div>
  </ion-avatar>
  <ion-icon name='camera' (click)="cameraClick()"></ion-icon>

  <div class='ion-margin-top ion-padding-right ion-padding-bottom' >
    <h2>&nbsp;&nbsp;{{name}}</h2>
  </div>

  @if (cameraClicked) {
    <div>
      @if (!platform.is('cordova')) {
        <div>
          @if (!browserCapture && !browserGetImage) {
            <div>
              @if (!platform.is('mobileweb')) {
                <ion-button class='ion-padding-left ion-margin-left' size='small'  (click)="getImageHTML5('take photo')" >Take Photo</ion-button>
              }
              <ion-button class='ion-padding-left ion-margin-left' size='small' (click)="getImageHTML5('get photo')">Get Photo</ion-button>
            </div>
          }
          @if (browserCapture) {
            <div>
              @if (browserCapture) {
                <image-capture (cancel)="getImageHTML5('cancel')" (gotPhoto)="gotPhoto($event)"></image-capture>
              }
            </div>
          }
          @if (browserGetImage) {
            <div style="margin-top:15px">
              <label for="myFileInput">Select File</label>
              <br>
                <input type="file" id="fileInput" accept="image/jpeg" (change)="handleFiles($event.srcElement!.files)">
              </div>
            }
          </div>
        }
      </div>
    }

  </ion-item>
