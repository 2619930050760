import { NgModule } from '@angular/core';
import { PopOverPage } from './pop-over';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    PopOverPage,

  ],
  imports: [
    CommonModule,
    IonicModule

  ],

  providers: [

  ]
})
export class PopOverPageModule {}
