import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commasAddSpace',
})
export class CommasAddSpacePipe implements PipeTransform {

  transform(value: any, ...args) {
    let result;
    if (value) {
      const array = value.toString();
      result = array.replace(/,/g, ',  ');
    }

    return result ? result : value;
  }
}
