@if (!readOnly) {
  <span [formGroup]="formgroup" style='width:100%'>
    @if (field.type === 'separator') {
        <ion-item color="primary" class="ion-margin-top" > {{field.label}} {{field.extraLabel}} </ion-item>
    }

    @if (field.type === 'text') {
      <ion-item [formGroup]="formgroup" >
        <ion-col size='8'>
          <ion-input label=" {{field.label}} {{field.extraLabel}} {{ field.optional?'(Optional)':' * ' }} " labelPlacement="floating" type="text" placeholder="" id="{{field.name}}" formControlName="{{field.name}}" class='ion-float-left'></ion-input>
        </ion-col>
        @if (field.allowPrivate) {
          <ion-col class="make-private" >
            @if (field.allowPrivate && field.type === 'text') {
              <ion-label  [attr.rows]="'5'">Make Private</ion-label>
            }
            @if (field.allowPrivate && field.type === 'text') {
              <ion-toggle item-start  formControlName="{{field.name}}_private"></ion-toggle>
            }
          </ion-col>
        }
      </ion-item>
    }
    @if (field.type === 'dollars') {
      <ion-item [formGroup]="formgroup">
        <ion-input  type="text"  label=" {{field.label}} {{field.extraLabel}} {{ field.optional?'(Optional)':' * ' }} " labelPlacement="floating" placeholder="" id="{{field.name}}" formControlName="{{field.name}}" ></ion-input>
      </ion-item>
    }

    @if (field.type === 'email') {
      <ion-item [formGroup]="formgroup">
        <ion-input  type="email" label=" {{field.label}} {{field.extraLabel}} {{ field.optional?'(Optional)':' * ' }} " labelPlacement="floating"  placeholder="" id="{{field.name}}" formControlName="{{field.name}}"></ion-input>
      </ion-item>
    }

    @if (field.type === 'postalcode') {
      <ion-item [formGroup]="formgroup">
        <ion-input  type="text" label=" {{field.label}} {{field.extraLabel}} {{ field.optional?'(Optional)':' * ' }} " labelPlacement="floating" type="text" placeholder="" id="{{field.name}}" formControlName="{{field.name}}"></ion-input>
      </ion-item>
    }

    @if (field.type === 'select') {
      <ion-item [formGroup]="formgroup">
        <ion-select label-placement="stacked"  multiple={{field.multiple}} id="{{field.name}}" formControlName="{{field.name}}" >
          <div slot="label"> <ion-text color="primary">{{field.label}} {{field.extraLabel}} {{ field.optional?'(Optional)':' * ' }} </ion-text> </div>
          @for (opt of field.options; track opt) {
            <ion-select-option class='select-option' value="{{opt}}" >{{opt}}</ion-select-option>
          }
        </ion-select>
      </ion-item>
    }

    @if (field.type === 'checkbox') {
      <ion-item [formGroup]="formgroup">
        <ion-checkbox justify="space-between" id='{{field.name}}' formControlName="{{field.name}}" class='ion-padding-left'> {{field.label}} {{field.extraLabel}} </ion-checkbox>
      </ion-item>
    }

    @if (field.type === 'textarea') {
      <ion-item [formGroup]="formgroup">
        <ion-label position="floating" color="primary" > {{field.label}} {{field.extraLabel}} {{ field.optional?'(Optional)':' * ' }} </ion-label>
        <ion-textarea id='{{field.name}}' formControlName="{{field.name}}" class='ion-text-wrap'></ion-textarea>
      </ion-item>
    }

    @if (field.type === 'toggle') {
      <ion-item [formGroup]="formgroup">
        <ion-label position="inline" color="primary" style="white-space: pre-wrap;" [attr.rows]="'5'"> {{field.label}} {{field.extraLabel}} </ion-label>
        <ion-toggle item-start item-start formControlName="{{field.name}}" checked="false" formControlName="{{field.name}}" ></ion-toggle>
      </ion-item>
    }

    @if (field.type === 'counter-input') {
      <ion-item [formGroup]="formgroup">
        <span class='label'>{{field.label}}</span>
        <counter-input id="{{field.name}}" formControlName="{{field.name}}" [counterRangeMax]="field.max" [counterRangeMin]="field.min"
          [_counterValue]="field.val" [counterIncrement]="field.incr">
        </counter-input>
      </ion-item>
    }
    @if (field.infoPage) {
      <div class='info-button'>
        <button (click)="getMoreInfo(field.infoPage, $event);"  class='ion-float-left ion-margin-left' type='button'>
          <img src='assets/info_image.jpg'   class='ion-float-left' class='info-icon'>
        </button>
      </div>
    }
    @if (field.verify && false) {
      <div class='info-button'>
        <button (click)="getMoreInfo(field.infoPage, $event);"  class='ion-float-left' type='button'>
          <img src='assets/info_image.jpg'   class='ion-margin-left' class='info-icon'>
        </button>
      </div>
    }
  </span>
}
<!--
<ion-item *ngIf="readOnly && !( !showContact && ( field.name =='phone' || field.name == 'streetAddress' || field.name == 'postalCode' ))" >
  -->
  @if (readOnly) {

      @if (showContact || !(!showContact && (  field.name ==='phone' || field.name === 'streetAddress' || field.name === 'postalCode' || field.hideRo ) ) ) {
        <ion-item>
          @if (field.type === 'separator') {
            <ion-list-header color='primary' class="margin-top"> {{field.label}} </ion-list-header>
          }
          @if (field.type === 'text') {
            <div class="ion-text-wrap">
              <span class="text-ro-label">{{field.label}}</span><br>
              <span class="text-ro-value ion-text-capitalize"> {{value}} </span>
            </div>
          }
          @if (field.type === 'email') {
            <div class="ion-text-wrap">
              <span class="text-ro-label">{{field.label}}</span><br>
              <span class="text-ro-value"> {{value}} </span>
            </div>
          }
          @if (field.type === 'dollars') {
            <div class="ion-text-wrap">
              <span class="text-ro-label ">{{field.label}}</span><br>
              <span class="text-ro-value ion-text-capitalize"> {{value | currency}} </span>
            </div>
          }
          @if (field.type === 'postalcode') {
            <div class="ion-text-wrap">
              <span class="text-ro-label">{{field.label}}</span><br>
              <span class="text-ro-value ion-text-uppercase">{{value}}</span>
            </div>
          }
          @if (field.type === 'select') {
            <div class="ion-text-wrap">
              <span class="text-ro-label">{{field.label}}</span><br>
              &nbsp;{{value | commasAddSpace | br2comma}}
            </div>
          }
          @if (field.type === 'textarea') {
            <div class="ion-text-wrap">
              <span class="text-ro-label">{{field.label}}</span><br>
              <span class="text-ro-value ion-text-capitalize"> {{value}} </span>
            </div>
          }
          @if (field.type === 'checkbox') {
            <div>
              <ion-icon name='{{value ? "checkbox" : "close"}}' class='ion-float-left'> </ion-icon>
            </div>
          }
          @if (field.type === 'checkbox') {
            <div class='checkbox-text' color="primary" class='ion-text-wrap' class='ion-float-left'> {{field.label}} </div>
          }
          @if (field.type === 'toggle') {
            <div class='toggle-ro-label' color="primary" class='ion-text-wrap' class='ion-float-left'> {{field.label}}: </div>
          }
          @if (field.type === 'toggle') {
            <div class='toggle-text' class='ion-float-left'> {{value ? "Yes" : "No"}}
              <ion-icon name='{{value ? "checkbox" : "close"}}'> </ion-icon>
            </div>
          }
        </ion-item>
      }

  }