<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <ion-list class="form-group border-bot">


    <ion-item class="form-group" style='display:none'>
      <label for="user">Username</label>
      <input type="text" class="form-control" id="user" placeholder="User Name" formControlName="user">
    </ion-item>

    <ion-item >
      <label for="resume" style='margin-top:3px;'>
        <ion-text >
          Choose Resume PDF File &nbsp;&nbsp;
        </ion-text>
      </label>
      <input type="file" name="file" id="resume" class="inputfile" (change)="onFileChange($event)"  accept="application/pdf" class='ion-margin-left'/>
    </ion-item>

    <ion-item >

      <ion-button type="button" size='small'(click)="clearFile()" class='ion-float-left'>
        Clear
      </ion-button>

      <ion-button type="submit" size='small'[disabled]="!form.controls['resume'].value || loading" class="btn-success ion-margin-left" class='ion-float-left ion-margin-right'>
        <ion-icon name='cloud-upload-outline'></ion-icon>
        &nbsp;Upload
        @if (loading) {
          <i class="fa fa-spinner fa-spin fa-fw"></i>
        }
      </ion-button>
    </ion-item>

    <ion-item>
      @if (resumeFile) {
        <ion-row [innerHTML]="resumeFile"  style='width:90%;' class='ion-float-left' ></ion-row>
      }
      @if (notPDF) {
        <ion-row class='alert' style='width:90%;' class='ion-float-left'>
          PDF files only
        </ion-row>
      }
    </ion-item>

  </ion-list>
</form>

