/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { WpProvider } from '../../providers/wp';
import { PopOverPage } from '../../pages/pop-over/pop-over';
import {MaskitoDirective} from '@maskito/angular';
import {MaskitoOptions} from '@maskito/core';


@Component({
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],

})
export class FormFieldComponent implements OnInit {
  @Input() field: any;
  @Input() formgroup: UntypedFormGroup;
  @Input() readOnly = false;
  @Input() checked = false;
  @Input() value: any;
  @Input() showContact = false;


  readonly dollarMask: MaskitoOptions = {
    mask: (value) => {
      const digitsCount = 4;
      return ['$', ...new Array(digitsCount || 1).fill(/\d/)];
    },
  };

  sels: any = [];
  tip = '';
  public content: any;

  constructor(
    public wp: WpProvider,
    public popCtrl: PopoverController
  ) {
    // console.log('Hello FormFieldComponent Component');

   }

  ngOnInit() {
    console.log(this.field.type);
  }

  getMoreInfo(page, ev) {
    this.wp.getPostContent(page)
    .subscribe(
      res => {
        this.sels[page] = res[0].content.rendered;
        this.tip = res[0].content.rendered;
        this.presentPopover(this.tip, ev);
        console.log('Post:', this.sels[page]);
      }
    );
  }

  async presentPopover(html: string, ev: any) {
    const content = {
      html: '',
      css: '',
      heading: ''
    };

    content.html = html;
    content.css = '';
    content.heading = 'Info:';
    const popover = await this.popCtrl.create({
      component: PopOverPage,
      cssClass: 'custom-popover',
      event: ev,
      translucent: true,
      componentProps: {
        content
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

}
