<!--
<div class='job-list-name ion-no-padding' *ngIf="f.value.type == 'text' && f.value.name =='jobName' " >
  <img class='child-icon ion-float-left' src="../assets/child.png">
  <div class='job-name ion-padding-left' >{{ j[f.value.name] }}</div>
</div>
-->

@if (f.value.type === 'text'  && f.value.name !=='jobName') {
  <ion-item class='job-list-item {{f.value.type}}'>
    <ion-col size="auto" class="heading">{{ f.value.label }}: </ion-col>
    <ion-col class='{{f.value.type}}'>{{j[f.value.name] | commasAddSpace}}</ion-col>
  </ion-item>
}

@if (f.value.type === 'select') {
  <ion-item class='job-list-item'>
    <ion-col size="auto" class="heading">{{ f.value.label }}: </ion-col>
    <ion-col  class='{{f.value.type}}'>{{j[f.value.name] | commasAddSpace}}</ion-col>
  </ion-item>
}

@if (f.value.type === 'textarea' && j[f.value.name]) {
  <ion-item class='job-list-item'  >
    <ion-col size="auto" class='{{f.value.type}}'>{{j[f.value.name] }}</ion-col>
  </ion-item>
}

@if (f.value.type === 'dollars') {
  <ion-item class='job-list-item'>
    <ion-col size="auto" class="heading">{{ f.value.label }}: </ion-col>
    <ion-col  class='{{f.value.type}}' class='ion-text-wrap'>{{j[f.value.name] | currency }}</ion-col>
  </ion-item>
}

@if (f.value.type === 'toggle') {
  <ion-item class='job-list-item'>
    <ion-col size="auto" class="heading">{{ f.value.label }}: </ion-col>
    <ion-col class='{{f.value.type}}' class='ion-text-wrap'>{{j[f.value.name] ? 'yes' : 'no' }}</ion-col>
  </ion-item>
}

@if (f.value.type === 'checkbox') {
  <ion-item class='job-list-item ion-text-wrap {{ f.value.type}} '>
    <ion-col size="auto" ngClass="{{j[f.value.name] ? 'selected' : 'not-selected'}} ">{{ f.value.label }} </ion-col>
  </ion-item>
}

@if (f.value.type === 'separator' && f.value.checkboxes) {
  <ion-list-header color="primary" > {{f.field.label}} {{f.field.extraLabel}} </ion-list-header>
}