
<ion-card class="ion-no-padding" >
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="dark" (click)="closeModal()">
          <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{provider.name}}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-card-content class="cal-content ion-no-padding ion-no-margin">

    @if (provider.calendar === 'full') {
      <ion-row class='ion-no-padding'>
        <availability-calendar #acc class="cal-pop" [readOnly]=true [schedule]="provider.schedule" [jobSchedule]="job.schedule" [title]="''" padding-top></availability-calendar>
      </ion-row>
    }

    @if (job.calendar === 'full') {
      <ion-row class='ion-no-padding'>
        <availability-calendar #acc class="cal-pop" [readOnly]=true [schedule]="provider.schedule" [jobSchedule]="job.schedule" [title]="''" padding-top></availability-calendar>
      </ion-row>
    }

    @if (provider.calendar === 'weekday') {
      <ion-row class='ion-no-padding'>
        <availability-calendar-days #acc [readOnly]=true [schedule]="provider.schedule" [jobSchedule]="job.schedule" [title]="''" padding-top></availability-calendar-days>
      </ion-row>
    }
  </ion-card-content>
</ion-card>
