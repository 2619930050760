export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBwFtUs7dhnSI9-pOD2QS5sEvzvUU2ZB8I",
    authDomain: "aba-connect-web.firebaseapp.com",
    projectId: "aba-connect-web",
    storageBucket: "aba-connect-web.appspot.com",
    messagingSenderId: "632904659871",
    appId: "1:632904659871:web:a1970f0543abfc128103aa"
  }
};
