import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the Comma2nlPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'comma2nl',
})
export class Comma2nlPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {
    let result;
    if (value) {
      result = value.replace(/,/mg, '\n');
    }

    return result ? result : value;
  }
}
