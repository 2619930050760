/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit,OnChanges, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit,  AfterViewChecked } from '@angular/core';
import { NavController, LoadingController } from '@ionic/angular';
import { WpProvider } from '../../providers/wp';
import { AppDataProvider } from '../../providers/app-data';
import { AuthenticationService } from '../../providers/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'jobs-matched',
  templateUrl: 'jobs-matched.html',
  styleUrls: ['./jobs-matched.scss']
})
export class JobsMatchedComponent implements OnInit {

  user: any;
  loggedUser: any;
  username: any;

  jobs: any = [];
  postIDs: any = [];
  provider: any;

  loading = false;

  log: any;
  showSummary: any = [];

  constructor(
    public wp: WpProvider,
    public authenticationService: AuthenticationService,
    public navCtrl: NavController,
    public appData: AppDataProvider,
    public loadingController: LoadingController,
    public route: ActivatedRoute,
  ) {

    console.log('Hello JobsMatchedComponent Component');
  }

  ngOnInit() {
    console.log('ngOnInit JobsMatchedComponent');
    this.route.data.subscribe(data => {
      this.init();
  });

  }

  init() {

    this.user = this.appData.getUser();
    if (this.user) {

      this.loggedUser = true;
      this.username = this.user.username;
     
      this.presentLoading();
      this.loading = true;
      this.wp.getJobsRanked(this.username).subscribe(
        res => {
          if (res.status === 'success') {
            this.jobs = res.jobs;
            console.log('matched jobs', this.jobs);
            this.postIDs = res.post_ids;
            this.loadingController.dismiss();
            this.loading = false;
          } else {
            this.loading = false;
          }
        }
      );

      this.wp.getProviderProfile(this.username).subscribe(
        res => {
          this.provider = res.profile;
          console.log('Provider profile', res.profile);
        }
      );
    }
  }

  isNumber(val) {
    return typeof val === 'number';
  }

  toggleSummary(i) {
    console.log(this.showSummary[i]);

    if (!this.showSummary[i]) {
      this.showSummary[i] = true;
    } else {
      this.showSummary[i] = false;
    }
  }

  viewCompanyContact(id) {
    this.appData.navForward('/dashboard-provider/view-company-contact', { id, job: this.jobs[id], postId: this.jobs[id].id });
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Getting jobs that match. Please wait...',
      duration: 2000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

}
