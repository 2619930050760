import { Component, OnInit, Input  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})

export class AvatarComponent implements OnInit {

  @Input() name: any;

  browserCapture = false;
  browserGetImage = false;
  img: HTMLImageElement;

  photo: any = false;
  cameraClicked = false;
  newPhoto = false;
  haveAvatar = false;
  avatarURL = 'assets/generic-avatar.png';

  constructor(
    public httpClient: HttpClient,
    public platform: Platform,

  ) {
  }

  ngOnInit() {
    console.log('Platform:', this.platform);
  }

  getImage(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  handleFiles(fileList) {
    this.browserGetImage = false;
    this.cameraClicked = false;

    if (!fileList.length) {
      console.log('No files selected!');

    } else {

      this.img.src = window.URL.createObjectURL(fileList[0]);
      this.photo = fileList[0];
      this.newPhoto = true;

    }
  }

  getImageHTML5(cmd) {
    if (cmd === 'take photo') { this.browserCapture = true; }
    if (cmd === 'cancel') { this.browserCapture = false; }
    if (cmd === 'get photo') { this.browserGetImage = true; }
  }

  cameraClick() {
    if (this.cameraClicked) {
      this.cameraClicked = false;
      this.browserCapture = false;
      this.browserGetImage = false;
    } else {
      this.cameraClicked = true;
    }
  }

  gotPhoto(c) {
    this.browserCapture = false;
    this.cameraClicked = false;

    this.img.src = c;
    this.getImage(this.img.src)
      .subscribe(res => {
        console.log('getImage Result', res);
        this.photo = res;
        this.photo.name = 'camera.jpg';
        this.newPhoto = true;
      });
  }



}
