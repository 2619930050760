
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import { RegisterPageModule } from './pages/register/register.module';
import { LoginModule } from './pages/login/login.module';
import { authGuard } from './auth.guard';

import { UpdateJobPage } from './pages/update-job/update-job';
import { NewJobPage } from './pages/new-job/new-job';
import { PopOverPage } from './pages/pop-over/pop-over';
import { ProvidersMatchedPage } from './pages/providers-matched/providers-matched';

import {DashboardCompanyPage} from './pages/dashboard-company/dashboard-company.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',

  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    // component: LoginModule
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'login-auth',
    // component: LoginModule
    loadChildren: () => import('./pages/login-auth/login-auth.module').then(m => m.LoginAuthPageModule)
  },
  {
    path: 'login-redir',
    // component: LoginModule
    loadChildren: () => import('./pages/login-redir/login-redir.module').then(m => m.LoginRedirPageModule)
  },
  {
    path: 'register',
    // component: RegisterPageModule
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register/provider',
    // component: RegisterPageModule
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register/family',
    // component: RegisterPageModule
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register/company',
    // component: RegisterPageModule
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register/agency',
    // component: RegisterPageModule
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },  
  
  {
    path: 'tos',
    loadChildren: () => import('./pages/tos/tos.module').then(m => m.TosPageModule)
  },

/*                  Company Dashboard                     */
  {
    path: 'company-dashboard',
    // component: DashboardCompanyPageModule,
    loadChildren: () => import('./pages/dashboard-company/dashboard-company.module').then(m => m.DashboardCompanyPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'company-dashboard/update-job',
    loadChildren: () => import('./pages/update-job/update-job.module').then(m => m.UpdateJobPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'update-job',
    loadChildren: () => import('./pages/update-job/update-job.module').then(m => m.UpdateJobPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'company-dashboard/new-job',
    loadChildren: () => import('./pages/new-job/new-job.module').then(m => m.NewJobPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'new-job',
    loadChildren: () => import('./pages/new-job/new-job.module').then(m => m.NewJobPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'company-dashboard/view-provider',
    loadChildren: () => import('./pages/view-provider/view-provider.module').then(m => m.ViewProviderPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'view-provider',
    loadChildren: () => import('./pages/view-provider/view-provider.module').then(m => m.ViewProviderPageModule),
    canActivate: [authGuard],
  },


/*                  Provider Dashboard                     */

  {
    path: 'dashboard-provider',  
    loadChildren: () => import('./pages/dashboard-provider/dashboard-provider.module').then(m => m.ProviderDashboardPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'new-job-pick',
    loadChildren: () => import('./pages/new-job-pick/new-job-pick.module').then(m => m.NewJobPickPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'providers-matched',
    loadChildren: () => import('./pages/providers-matched/providers-matched.module').then(m => m.ProvidersMatchedPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'view-job',
    loadChildren: () => import('./pages/view-job/view-job.module').then(m => m.ViewJobPageModule),
    canActivate: [authGuard],
  },

  {
    path: 'provider-profile',
    loadChildren: () => import('./pages/provider-profile/provider-profile.module').then(m => m.ProviderProfilePageModule),
    canActivate: [authGuard],
  },
  {
    path: 'view-company-contact',
    loadChildren: () => import('./pages/view-company-contact/view-company-contact.module').then(m => m.ViewCompanyContactPageModule),
    canActivate: [authGuard],
  },



  
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/signup-aba/signup-aba.module').then(m => m.SignUpABAModule)
  },
  {
    path: 'login-auth',
    loadChildren: () => import('./pages/login-auth/login-auth.module').then( m => m.LoginAuthPageModule)
  },
  {
    path: 'login-redir',
    loadChildren: () => import('./pages/login-redir/login-redir.module').then( m => m.LoginRedirPageModule)
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false , preloadingStrategy: PreloadAllModules }) ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
