@for (j of jobs; track j; let ji = $index) {
  <ion-list>
    <ion-item >
      <ion-label>
        <p>Position: {{ j.jobName }}</p>
      </ion-label>
      <ion-button slot="end" (click)="viewCompanyContact(ji)">
        <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
        Provider Info
      </ion-button>
    </ion-item>


  </ion-list>
  <ion-grid class="jobs">


    <ion-row class="ion-no-padding" >

      @for (f of j.match | keyvalue; track f; let i = $index) {

        <div class="profile-match-fields" text-center>
          @if (f.value) {
            <profile-match-fields [matchData]="f.value" [label]="f.value['label']"
              [index]="i" mode='job' [job]='jobs[ji]'  [provider]='provider'
              (showScheduleClick)='toggleSummary($event)' >
            </profile-match-fields>
          }          
        </div>
      }

    </ion-row>

    @if (false) {
      <ion-row class='border-bot ion-padding-bottom ion-text-center'>
        @if (showSummary[ji]) {
          <ion-button size="full"  (click)="toggleSummary(ji)">
            <span class='best-fit'>
              <span class='best-fit-highlite'>
                Best-Fit&reg;
              </span>
              Job Details
            </span>
            <ion-icon name="caret-down-outline"></ion-icon>
          </ion-button>
        }
        @if (!showSummary[ji]) {
          <ion-button size="full"  (click)="toggleSummary(ji)">
            <span class='best-fit'>
              <span class='best-fit-highlite'>
                Best-Fit&reg;
              </span>
              Job Details
            </span>
            <ion-icon name="caret-forward-outline"></ion-icon>
          </ion-button>
        }
      </ion-row>
    }

    @if (showSummary[ji]) {
      <ion-row class='summary'>

        <ion-list [inset]="true" lines="full" >
          @for (f of j.match | keyvalue; track f; let i = $index) {
            @if (f.key !=='schedule') {
              <ion-item >
                <ion-col class="label ion-margin-right">
                  {{f.value['label']}}:
                </ion-col>
                @if (!isNumber(f.value['pds'])) {
                  <ion-col >
                    <ion-text  class="value">
                      {{f.value['matches'] | br2comma |comma2nl }}
                    </ion-text>
                    @if (!f.value['match'] && !isNumber(f.value['pds'])) {
                      <ion-text class="alert" color="alert">
                        <br>{{f.value['not_matched'] | br2comma | comma2nl }}
                        </ion-text>
                      }
                    </ion-col>
                  }
                  @if (isNumber(f.value['pds'])) {
                    <ion-col  class="value">
                      {{f.value['jds'] | currency}}
                      @if (j.negotiable) {
                        <ion-text>(negotiable)</ion-text>
                      }
                      @if (!j.negotiable) {
                        <ion-text>(not negotiable)</ion-text>
                      }
                      @if (!f.value['match']) {
                        <ion-text class="alert" color="alert">
                          <br>{{f.value['pds'] | currency }}
                          </ion-text>
                        }
                      </ion-col>
                    }
                  </ion-item>
                }
              }
              @if (j.jobDescription) {
                <ion-item class='ion-text-wrap'>
                  <span class="ac-item-heading">Addition Information:</span><br>
                  <span class="ac-item-textarea"> {{j.jobDescription | br2nl }}</span>
                </ion-item>
              }
              @if (j.calendar !== 'none') {
                <ion-item class='ion-padding-top ion-margin-top '>
                  <availability-calendar  #acc [readOnly]=true [schedule]=provider.schedule [jobSchedule]=j.schedule [title]="''"></availability-calendar>
                </ion-item>
              }
            </ion-list>
          </ion-row>
        }
      </ion-grid>
    }

    @if ((jobs.length === 0) && !loading) {
      <ion-item>
        No "{{appData.jobGroup}}" jobs match your profile or are available.
      </ion-item>
    }

