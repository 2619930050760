@if (!justRegistered) {
  <ion-toolbar>
    <ion-title color='dark'> Subscriptions({{subs.length}}) Jobs({{availableJobs}})</ion-title>
    <ion-buttons end>
      <ion-button small (click)="addSubscription()">
        <ion-icon name='add'> </ion-icon> &nbsp;Add
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
}

@if (justRegistered) {
  <ion-toolbar>
    <ion-title class='ion-text-wrap'> Thanks for registering!</ion-title>
  </ion-toolbar>
}

@if (justRegistered) {
  <ion-card class='trial'>
    <ion-list>
      <ion-item class='border-bot' text-center>
        To start your <b>FREE</b> 15 day trial,
        <br> then click 'Start Trial'.
        </ion-item>
        <ion-item class='border-bot'>
          <button class=' ion-float-left' (click)="showTOS()">View<b> Terms of Service</b></button>
          @if (justRegistered) {
            <button type="submit" class=' ion-float-right' padding-vertical (click)="startTrial()">
              <ion-icon name='checkmark'> </ion-icon> &nbsp; Start Trial
            </button>
          }
        </ion-item>
      </ion-list>
    </ion-card>
  }


  @if (!justRegistered) {
    <ion-list>
      <ion-item class='border-bot account'>
        <ion-row>
          <ion-col col-2>
            Active
          </ion-col>
          <ion-col col-2>
            Jobs
          </ion-col>
          <ion-col col-2>
            Days
          </ion-col>
          <ion-col col-3>
            Start Date
          </ion-col>
          <ion-col col-3>
            End Date
          </ion-col>
        </ion-row>
      </ion-item>
      @for (s of subs; track s; let i = $index) {
        <ion-item class='sub-item' class='account' [ngClass]="{'inactive': !s.active}">
          <ion-row>
            <ion-col col-2>
              @if (s.active) {
                <ion-icon name='checkbox-outline'></ion-icon>
              }
            </ion-col>
            <ion-col col-2>
              {{s.numberOfJobs}}
            </ion-col>
            <ion-col col-2>
              {{s.numberOfDays}}
            </ion-col>
            <ion-col col-3>
              {{s.startDate | date}}
            </ion-col>
            <ion-col col-3>
              {{s.endDate | date}}
            </ion-col>
          </ion-row>
        </ion-item>
      }
      @if (loading) {
        <ion-item>
          Loading...
        </ion-item>
      }
    </ion-list>
  }