@for (p of providers; track p; let pi = $index) {
  <div class="ion-no-padding" >
    <ion-grid class="ion-padding-left ion-float-left ion-margin-bottom providers-matched" >
      <ion-row class='ion-no-padding'>
        <ion-col size="2">
          @if (!p.haveAvatar) {
            <img id='avatar' class='landscape' src="assets/generic-avatar.png" class='matched-photo'>
          }
          @if (p.haveAvatar) {
            <img id='avatar' class='landscape' src="{{avatarURL}}{{p.username | lowercase}}_avatar.jpg{{ts}}" class='matched-photo'>
          }
        </ion-col>

        <ion-col size="6">
          &nbsp;<b>{{p.name}}</b> <br>
          @if (p.qualification) {
            <ion-text >&nbsp;{{p.qualifications}}</ion-text>
          }
          @if (!p.qualification) {
            <ion-text >&nbsp;{{p.jobGroup}}</ion-text>
          }      
        </ion-col>
        <ion-col size="4">
          <ion-button size='small'  color='secondary' (click)="viewProvider(pi)" class="ion-float-right">
            Provider Info 
            @if (p.resume !== 0) {
              <ion-icon name="document-attach-outline"></ion-icon>
            }
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </ion-button>
        </ion-col>

      </ion-row>

      <ion-row  class='ion-no-padding' >
        @for (f of p.match | keyvalue; track f; let im = $index) {
          <div class=" profile-match-fields" text-center >
            @if (f.value) {
              <profile-match-fields [matchData]="f.value" [label]="f.value['label']"
                [index]="im" mode='provider' [job]='job'  [provider]='providers[pi]'
                (showScheduleClick)='toggleSummary($event)'>
              </profile-match-fields>
            }
          </div>
        }
      </ion-row>
    </ion-grid>
  </div>
}
