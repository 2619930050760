/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { AppDataProvider } from '../../providers/app-data';
import { UntypedFormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Generated class for the AvailabilityCalendarDaysComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'availability-calendar-days',
  templateUrl: 'availability-calendar-days.html'
})
export class AvailabilityCalendarDaysComponent {
  @Input() schedule: UntypedFormArray;
  @Input() jobSchedule: UntypedFormArray;

  @Input() title: string;
  @Input() readOnly = false;

  weekDays: any;
  dayTimes: any;

  constructor(
    public appData: AppDataProvider
  ) {
    console.log('Hello AvailabilityCalendarDaysComponent Component');
    this.weekDays = this.appData.weekDays;
    this.dayTimes = this.appData.dayTimes;
  }

  public toggleAvailability(f: UntypedFormArray, j) {
    if (f.controls[j].value === false) {
      f.controls[j].setValue(true);
    } else {
      f.controls[j].setValue(false);
    }
  }

  public isNull(x: any) {
    if (typeof x === 'undefined') {
      return true;
    }
    if (x == null) {
      return true;
    }

    if (x === null) {
      return true;
    }
    return false;
  }

  selectDay(i: any) {
    this.schedule.controls.forEach( (control: any, idx) => {
      if (idx >= 1 && idx <= 12) {
        this.toggleAvailability(control.controls.days, i);
      }
    });
  }

}
