<!-- Generated template for the ProviderProfilesComponent component -->
<div>
  <ion-card>
    <ion-card-content>
      <ion-list>
        @for (p of providers; track p; let i = $index) {
          <ion-item class='provider-item'>
            <ion-fab top right>
              <button ion-fab mini (click)="viewProvider(i)" class='fab'>
                <ion-icon name="person"></ion-icon>
              </button>
            </ion-fab>
            <ion-row>
              <ion-col class='name'>
                {{i+1}}
                <ion-icon ios="ios-person" md="md-person"></ion-icon>
                <span class="ac-item-heading">Name:</span> Not Available
              </ion-col>
            </ion-row>
            <ion-row class='border-bot'>
              <ion-col class='location'>
                <span class="ac-item-heading">Home Location:</span> {{p.city}}</ion-col>
              </ion-row>
              <ion-row class='border-bot'>
                <ion-col class='education'><span class="ac-item-heading">Education:</span> {{p.education}}</ion-col>
                <ion-col class="experience"><span class="ac-item-heading">Experience:</span> {{p.experienceLevel}} </ion-col>
                <ion-col class="qualifications"><span class="ac-item-heading">Qualifications:</span> {{p.qualifications}} </ion-col>
                <ion-col class="rate">
                  <span class="ac-item-heading">Preferred Rate:</span> {{p.preferredRate | currency}}
                  @if (p.negotiable) {
                    <span> (negotiable)</span>
                  }
                  @if (!p.negotiable) {
                    <span>(not negotiable)</span>
                  }
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="languages" class='ion-text-wrap'>
                  <span class="ac-item-heading">Languages:</span> <br> {{p.language | commasAddSpace}}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="locations" class='ion-text-wrap'>
                  <span class="ac-item-heading">Locations:</span><br> {{p.location | commasAddSpace}}
                </ion-col>
              </ion-row>
            </ion-item>
          }
          @if (noMore) {
            <ion-item text-center>
              End of Providers
            </ion-item>
          }
          @if (!noMore) {
            <button type='button' class='' (click)="getMore()">Load More Providers</button>
          }
          @if (providers.length === 0) {
            <ion-item>
              Loading.....
            </ion-item>
          }

        </ion-list>
      </ion-card-content>
    </ion-card>
  </div>