import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the Br2commaPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'br2comma',
})
export class Br2commaPipe implements PipeTransform {

  transform(value: string, ...args) {
    let result;
    if (value) {
      result = value.replace(/<br\s*\/?>/mg, ', ');
    }

    return result ? result : value;
  }
}
