/* eslint-disable @angular-eslint/component-selector */

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { PopOverPage } from '../../pages/pop-over/pop-over';
import { PopoverCalendarPage } from '../../pages/popover-calendar/popover-calendar.page';
import { AppDataProvider } from '../../providers/app-data';
import { modalController } from '@ionic/core';

@Component({
  selector: 'profile-match-fields',
  templateUrl: 'profile-match-fields.html',
  styleUrls: ['profile-match-fields.scss']
})
export class ProfileMatchFieldsComponent implements OnInit {
  @Input() matchData: any;
  @Input() label: string;
  @Input() jobField: any;
  @Input() index: number;
  @Input() mode: string;
  @Input() job: any;
  @Input() provider: any;

  @Output() showScheduleClick = new EventEmitter<number>();

  html: any;
  popover: any;

  timer: any;

  content: any;

  constructor(
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public appData: AppDataProvider
  )  {
    // console.log('Hello ProfileMatchFieldsComponent ');

  }

  showSchedule(value: number) {
    this.showScheduleClick.emit(value);
  }

  ngOnInit() {
    this.init()
  }

  init() {

  }

  closePopOver(m, event: Event) {
    console.log('mouse out');
  }

  togglePopOver(p: any, event: Event) {
    const m = p.matchData;
    console.log('p:', p);
    let isMatched = '<ion-text color="primary"> Matched </ion-text>';
    if (m.not_matched) {
      isMatched = '<ion-text color="alert"> Not Matched </ion-text>';
    }

    if (this.mode === 'provider') {
      switch (m.type) {
        case 'checkbox_section': {
          if (m.not_matched) {
            this.html = '<b>Provider is missing: </b>' +  m.not_matched;
          } else {
            this.html = '<b>Provider matches requirements</b>';
          }
          break;
        }
        case 'is_in_options': {
          if (m.not_matched) {
            this.html = '<b>Provider is missing: </b>' +  m.jds;
          } else {
            this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>Provider matches requirements</b>' +  m.jds;
          }
          break;
        }
        case 'equal_or_greater': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>Provider has: </b>' +  m.pds;
          break;
        }
        case 'all_are_in_options': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>Provider has: </b>' +  m.pds;
          break;
        }
        case 'equal_or_more': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>Provider offers: </b>' +  m.pds;
          break;
        }
        case 'schedule': {

          break;
        }
        default: {
          this.html = 'default!!!! <b>Job Requires: </b>' + m.jds + '<br><b>Provider Has: </b>' + m.pds;
          break;
        }
     }
    } else if (this.mode === 'job') {
      switch (m.type) {
        case 'checkbox_section': {
          if (m.not_matched) {
            this.html = '<b>You are missing: </b>' +  m.not_matched;
          } else {
            this.html = '<b>You match requirements</b>';
          }
          break;
        }
        case 'is_in_options': {
          if (m.not_matched) {
            this.html = '<b>You missing: </b>' +  m.jds;
          } else {
            this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>Provider matches requirements</b>' +  m.jds;
          }
          break;
        }
        case 'equal_or_greater': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>You have: </b>' +  m.pds;
          break;
        }
        case 'all_are_in_options': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>You are missing: </b>' +  m.not_matched;
          break;
        }
        case 'equal_or_more': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>You have: </b>' +  m.pds;
          break;
        }
        case 'equal_or_less': {
          this.html = '<b>Job Requires: </b>' + m.jds + '<br><b>You have: </b>' +  m.pds;
          break;
        }
        case 'schedule': {

          break;
        }
        default: {
          this.html = 'default!!!! <b>Job Requires: </b>' + m.jds + '<br><b>Provider Has: </b>' + m.pds;
          break;
        }
     }
    }

    if (m.type === 'schedule') {
      if (this.mode === 'provider') {
        this.openPopoverSchedule(this.provider, this.job, p );
      } else if (this.mode === 'job') {
        this.openPopoverSchedule(this.job, this.provider, p );
        // this.openPopoverSchedule(this.job, this.provider, p );
      }

    } else {
      // this.html = '<div class="popover-content">' + this.html + '</div>';
      this.openPopover(m.label + ' - ' + isMatched, this.html, event);
    }
  }

  async openPopoverSchedule(provider: any, job: any, p: any) {
    const modal = await this.modalCtrl.create({
      component: PopoverCalendarPage,
      cssClass: 'ac-modal-schedule',
      // swipeToClose: true,
      componentProps: {
        job: this.job,
        provider: this.provider,
        matchData: p
      }
    });

    modal.onDidDismiss().then((result) => {
      console.log('popover result', result);
    });

    return await modal.present();
  }

  async openPopover(label: string, html: string, ev: any) {

    this.content = {};
    this.content.html = html;
    this.content.css = '';
    this.content.heading = label;
    console.log(window.innerWidth - 1080);

    const popover = await this.popoverCtrl.create({
      component: PopOverPage,
      event: ev,
      cssClass: 'popover-setting',
      componentProps: {
        content: this.content
      },
      translucent: true,
      size: 'auto',

    });

    popover.onDidDismiss().then((result) => {
      console.log('popover result', result);
    });

    return await popover.present();
  }
}
