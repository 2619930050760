import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-pop-over',
  templateUrl: 'pop-over.html',
  styleUrls: ['./pop-over.scss'],
})
export class PopOverPage implements OnInit {

  content: any;

  constructor(

  ) {
    // this.html = this.navParams.get('content');
    // this.header = this.navParams.get('header');


  }

  ngOnInit() {
    console.log('ngOnInit PopOverPage');
    console.log(this.content);
  }

}
