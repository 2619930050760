@if (readOnly === false) {
  <div #acc class='cal' FormArrayName="schedule">
    <ion-grid fixed>
      <ion-row class="cal-weedays" class='ion-no-padding'>
        @for (weekday of weekDays; track weekday; let i = $index) {
          <ion-col class='cal-day ion-no-padding'>
            <ion-button mall  class='btn-day' (click)="selectDay(i)">
              {{weekday.day}}
            </ion-button>
          </ion-col>
        }
      </ion-row>
      @for (t of schedule.controls; track t; let i = $index) {
        <ion-row style='border-bottom: 1px white solid' FormGroupName="i"
          class='ion-no-padding'>
          @for (d of schedule.controls[i]['controls']['days']['controls']; track d; let j = $index) {
            <ion-col class='cal-select' nopadding>
              <input class="tgl tgl-avail" id="ac-{{i}}-{{j}}" type="checkbox" FormGroupName="j" [checked]="schedule.controls[i]['controls']['days'].controls[j].value"
                (change)="toggleAvailability(schedule.controls[i]['controls']['days'],j)">
              <label id="ac-lb-{{i}}-{{j}}" for="ac-{{i}}-{{j}}" class='tgl-btn ion-no-padding'></label>
            </ion-col>
          }
        </ion-row>
      }
    </ion-grid>
  </div>
}

@if (readOnly === true) {
  <div #acc class='cal-ro'>
    <ion-grid fixed no-margin>
      <ion-row class="cal-weedays">
        @for (weekday of weekDays; track weekday) {
          <ion-col class='cal-day ion-no-padding'>
            {{weekday.day}}
          </ion-col>
        }
      </ion-row>
      <div>
        @for (dayTime of dayTimes; track dayTime; let i = $index) {
          <ion-row class='cal-row' class='ion-no-padding'>
            @for (weekday of weekDays; track weekday; let j = $index) {
              <ion-col class='cal-select'>
                @if (jobSchedule) {
                  <div>
                    @if (!isNull(jobSchedule[i]) && !isNull(schedule[i])) {
                      <div>
                        @if (jobSchedule[i].days[j] ) {
                          <div>
                            @if (schedule[i].days[j]) {
                              <ion-icon name='checkmark-circle'></ion-icon>
                            }
                            @if (!schedule[i].days[j]) {
                              <ion-icon color="danger" name='close-circle'></ion-icon>
                            }
                          </div>
                        }
                        @if (!jobSchedule[i].days[j] ) {
                          <div>
                            @if (schedule[i].days[j] === true) {
                              <ion-icon name='checkmark'></ion-icon>
                            }
                            @if (schedule[i].days[j] === false) {
                              <ion-icon name='remove'></ion-icon>
                            }
                          </div>
                        }
                      </div>
                    }
                    @if (isNull(jobSchedule[i]) || isNull(schedule[i])) {
                      <div>
                        <ion-icon name='remove'></ion-icon>
                      </div>
                    }
                  </div>
                }
                @if (!jobSchedule) {
                  <div>
                    @if (!isNull(schedule[i])) {
                      <div>
                        @if (schedule[i].days[j] === true) {
                          <ion-icon name='checkmark-circle'></ion-icon>
                        }
                        @if (schedule[i].days[j] === false) {
                          <ion-icon name='remove'></ion-icon>
                        }
                      </div>
                    }
                  </div>
                }
              </ion-col>
            }
          </ion-row>
        }
        @if (jobSchedule) {
          <div class='legend'>
            <ion-icon name='checkmark-circle'></ion-icon> Job Schedule Matched to Provider<br>
            <ion-icon color="danger" name='close-circle'></ion-icon> Job Schedule NOT Matched to Provider<br>
            <ion-icon name='checkmark'></ion-icon> Provider is Available<br>
            <ion-icon name='remove'></ion-icon> Provider is NOT Available<br>
          </div>
        }
        @if (!jobSchedule) {
          <div class='legend'>
          </div>
        }
      </div>
    </ion-grid>
  </div>
}

