/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, Input } from '@angular/core';

import { AppDataProvider } from '../../providers/app-data';
import { WpProvider } from '../../providers/wp';
import { AuthenticationService } from '../../providers/authentication/authentication.service';
import { ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'provider-profiles',
  templateUrl: './provider-profiles.component.html',
  styleUrls: ['./provider-profiles.component.scss']
})
export class ProviderProfilesComponent implements OnInit {
  @Input() ranked = false;

  loggedUser: any;
  username: any;
  profile: any;
  haveProfile: boolean;

  displayName: string;
  providers: any = [];
  user: any;
  postIDs: any = [];

  page = 1;
  noMore = false;
  constructor(
    public appData: AppDataProvider,
    public wp: WpProvider,
    public authenticationService: AuthenticationService,
    public toastController: ToastController,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    console.log('Hello ProviderProfilesComponent Component');
    this.user = this.appData.getUser();
    if (this.user) {

      this.loggedUser = true;
      this.username = this.user.username;

      this.wp.getCompanyProviders(this.username, this.page)
        .subscribe(
          res => {
            if (res.status === 'success') {
              this.providers = res.providers;
              this.postIDs = res.post_ids;
              this.haveProfile = true;
            } else {
              this.haveProfile = false;
            }
          });
    }

  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  getMore() {
    this.page++;

    this.wp.getCompanyProviders(this.username, this.page)
      .subscribe(
        res => {
          if (res.status === 'success') {
            if (res.providers.length === 0) { this.noMore = true; }
            this.providers = [...this.providers, ...res.providers];
            this.postIDs = [...this.postIDs, ...res.post_ids];
            this.haveProfile = true;
          } else {
            this.haveProfile = false;
          }
        });
  }

  ionViewDidLoad() {
    this.user = this.appData.getUser();
  }

  async presentToast(s) {
    const toast = await this.toastController.create({
      message: s,
      duration: 3000
    });
    toast.present();
  }


  viewProvider(id) {
    this.appData.nav('/view-provider', { id, provider: this.providers[id], postId: this.postIDs[id] });
  }

}
