import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppDataProvider } from '../app-data';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly injector: Injector,

    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('Auth Interseptor Request:', request);
        const appData = this.injector.get(AppDataProvider);
        if (request.url.search('slug=terms-of-service') !== -1) {
            return next.handle(request);
        }

        const user = appData.getUser();

        // const token = user.token;
        // console.log('type of token', typeof(token));

        if (user === null) {
            return next.handle(request);
        }

        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + user.token) });
        // console.log(request);
        return next.handle(request);

    }
}
