import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'objectKeys', pure: false })
export class ObjectKeysPipe implements PipeTransform {
  transform(value: any, args: any[] ): any {
    // tslint:disable-next-line: only-arrow-functions
    return Object.keys(value).map( function(key) {
      const pair = {};
      const k = 'key';
      const v = 'value';


      pair[k] = key;
      pair[v] = value[key];

      return pair;
    });
  }
}
