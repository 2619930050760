/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';
import { WpProvider } from '../../providers/wp';
import { AuthenticationService } from '../../providers/authentication/authentication.service';

import { Router, ActivatedRoute } from '@angular/router';
import { AppDataProvider } from '../../providers/app-data';

@Component({
  selector: 'account',
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.scss'],
})
export class AccountComponent {

  @Input() justRegistered = false;

  sStartDate = '2021-06-01';
  sEndDate = '2021-07-01';
  jobListingsPurchased = 1;

  loading = true;
  user: any;
  loggedUser = false;
  username: string = '';

  availableJobs: number = 0;

  subs: any = [];

  tos = false;

  date: Date;
  endDate: Date;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public appData: AppDataProvider,
    public wp: WpProvider,
    public authenticationService: AuthenticationService,
  ) {
    console.log('Hello AccountComponent Component');

    this.date = new Date();
    this.endDate = new Date();

    this.user = this.appData.getUser();
    if (this.user) {

      this.loggedUser = true;
      this.username = this.user.username;

      this.wp.getSubscriptions(this.username).subscribe(
        ( res: { subs: any; }) => {
          this.loading = false;
          console.log('Subscriptions res:', res);
          this.subs = res.subs;

          this.wp.getSubscriptionAvailableJobs(this.username).subscribe(
            ( res2: { available_jobs: number; }) => {
              this.availableJobs = res2.available_jobs;
            }
          );
        }
      );
    }
  }

  showTOS() {
    this.router.navigate(['/tos']);
  }

  startTrial() {
    const req = { numberOfJobs: 1, numberOfDays: 15, startDate: this.date, endDate: this.addDays(this.date, 15), active: true };
    this.subs.push(req);

    console.log('sub:', req);

    this.wp.addSubscriptions(this.username, req).subscribe(
      (      res: any) => {
        console.log('Subscriptions add res:', res);

        this.wp.getSubscriptions(this.username).subscribe(
          (          res1: { subs: any; }) => {
            this.loading = false;
            console.log('Subscriptions res:', res1);
            this.subs = res1.subs;

            this.wp.getSubscriptionAvailableJobs(this.username).subscribe(
              (              res2: { available_jobs: number; }) => {
                this.availableJobs = res2.available_jobs;
                this.router.navigate(['company-dashboard', { queryParams: { justRegistered: false } } ]);
              }
            );
          }
        );
      }
    );

    this.justRegistered = false;

    console.log('Subscription form values:', this.subs);
  }

  addDays(date: Date, numberOfDays: number): Date {
    const dd = new Date();
    dd.setDate(this.date.getDate() + numberOfDays);
    return dd;
  }

  addSubscription() {
    this.router.navigate(['add-subscription', { queryParams: { subs: this.subs, username: this.username } }]);
  }

}


