import { Injectable } from '@angular/core';
import { AppDataProvider } from '../providers/app-data';

@Injectable()
export class JobGroupsProvider {


  constructor(
    // tslint:disable-next-line: variable-name
    public appData: AppDataProvider,

  ) {

  }

  public jobGroups: any = {

    'Behaviour Interventionist': {
      jobs: ['Behaviour Interventionist', 'Senior Bevahioural Interventionist', 'ABA Support Worker'],
      jobFF: this.appData.jobFormFieldsBI,
      providerFF: this.appData.providerFormFieldsBI,
      calendar: 'full'

    },

    'Respite Worker': {
      jobs: ['Respite Worker'],
      jobFF: this.appData.jobFormFieldsRW,
      providerFF: this.appData.providerFormFieldsRW,
      calendar: 'full'

    },

    'Behaviour Consultant': {
      jobs: ['Behaviour Consultant'],
      jobFF: this.appData.jobFormFieldsBC,
      providerFF: this.appData.providerFormFieldsBC,
      calendar: 'none'
    }

  };

}
