import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Config from './config';

import { Storage } from '@ionic/storage-angular';

import { JobGroupsProvider } from './jobgroups';

export class ProviderProfile {
  profile: any;
  name: string;
  status: string;
  haveAvatar: boolean;
}

@Injectable()
export class WpProvider {

  constructor(

    public http: HttpClient,
    public jobGroups: JobGroupsProvider,
    public storage: Storage

  ) {
    console.log('Hello WpProvider Provider');
  }

  setCompanyContactedProvider(provider, username) {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'company/contacted_provider', { provider, username });
  }

  setProviderContactedCompany(company, username) {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'provider/contacted_company', { company, username });
  }

  getPostContent(slug): any {
    return this.http.get(Config.WORDPRESS_REST_API_URL + 'posts?slug=' + slug );
  }

  getABAConfiguration(config): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'logon/aba_configuration', { config } );
  }

  getSubscriptions(username): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'subscription/get/', {username});
  }
  addSubscriptions(username, subs): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'subscription/add/', { username, subs });
  }
  getSubscriptionAvailableJobs(username): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'subscription/get/available_jobs', { username });
  }



  saveProviderResume(resume): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'provider/add_resume/', resume);
  }
  saveProviderProfileImage(photo): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'provider/add_photo/', photo);
  }
  getProfileImage(username): any {
    return this.http.get(Config.WORDPRESS_ABA_URL + 'provider/get_photo?username=' + username, {responseType: 'blob'});
  }
  saveProviderProfile(profile): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'provider/save_profile/', profile );
  }
  getProviderProfile(username): any {
    return this.http.post <ProviderProfile>(Config.WORDPRESS_ABA_URL + 'provider/get_profile?', { username }, );
  }
  getProviderProfilebyId(id): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'provider/get_profile_by_id?', { id }, );
  }
  getProviderResume(id): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'provider/get_resume?', { id }, );
  }
  getAllJobs(): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'company/get_all_jobs?', {  }, );
  }
  getAllActiveJobs(jobGroup?: string): any {
    if (!jobGroup) { jobGroup = ''; }
    return this.http.post(Config.WORDPRESS_ABA_URL + 'company/get_all_active_jobs?', {jobGroup}, );
  }
  getJobsRanked(username, ): any {
    const jobGroups = this.jobGroups.jobGroups;
    return this.http.post(Config.WORDPRESS_ABA_URL + 'company/get_jobs_ranked?', { username, jobGroups}, );
  }
  saveNewJob(username, job, name): any {
    const j = job.replace(/\n/g, '<br />');
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/save_new_job/', {username, job, jobName: name});
  }
  updateJob(username, job, id, name): any {
    const j = job.replace(/\n/g, '<br />');
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/update_job/', {username, job: j, postID: id, jobName: name});
  }


  getCompanyProfile(username): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/get_profile?', { username }, );
  }
  saveCompanyProfile(profile): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'company/save_profile/', profile);
  }
  getCompanyJobs(username): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/get_jobs?', { username }, );
  }
  getCompanyJob(username, id): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/get_jobs?', { username, id }, );
  }
  getCompanyProviders(username, page): any {
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/get_providers?', { username, page }, );
  }
  getCompanyProvidersRanked(username, jobID): any {
    const jobGroups = this.jobGroups.jobGroups;
    return this.http.post( Config.WORDPRESS_ABA_URL + 'company/get_providers_ranked?', { username, job_id: jobID, jobGroups }, );
  }


  sendAppData(provider, company, job): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'register/app_data/', { provider, company, job });
  }
  setProviderStatus(username, status) {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'provider/set_status', { username, status }, );
  }

  getUser() {
    return this.storage.get('User');
  }

  getUserMetaAll(username): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'user/get_user_meta_all', { username });
  }

  getUserMeta(username, meta): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'user/get_user_meta', { meta, username });
  }

  setUserMeta(username, meta, value): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'user/set_user_meta', { meta, username, value });
  }

  deleteSelf(userId) {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'user/delete_self', {id: userId});
  }

  deleteUser(userId) {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'user/delete_user', {id: userId});
  }

  doRegister(userData): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'register/new_user', userData);
  }

  doVerifyUsernamePassword(username, email): any {
    return this.http.get(Config.WORDPRESS_ABA_URL + 'register/verify_username?username=' + username + '&email=' + email);
  }

  doRequestPasswordChange(email): any {
    return this.http.get(Config.WORDPRESS_ABA_URL + 'logon/request_password_change?email=' + email);
  }
  doRequestEmailPassword(username): any {
    return this.http.get(Config.WORDPRESS_ABA_URL + 'logon/request_email_address?username=' + username);
  }

  doOneTime(oneTime, data): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'one_time/' + oneTime, data);
  }

  doRequestVerify(username, which): any {
    return this.http.post(Config.WORDPRESS_ABA_URL + 'logon/request_verify', {username, which});
  }

}
