import { Injectable, inject } from '@angular/core';
import { Auth, User, createUserWithEmailAndPassword, user, updateProfile, signInWithEmailAndPassword, signOut, AuthError, AuthErrorCodes, AuthCredential, GoogleAuthProvider,signInWithPopup } from '@angular/fire/auth';
import { update } from 'cypress/types/lodash';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth: Auth = inject(Auth);
  
  constructor() { }

  signUpGoogle() {
    signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  signUp(username:string, email: string, password: string) {

    createUserWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        updateProfile(userCredential.user, {
          displayName: username
        })
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  login(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password) 
      .then((userCredential) => {
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error);
      });    
  }

  signOut() {
    signOut(this.auth).then(() => {
      console.log('Signed Out');
      signOut(this.auth);
    }).catch((error) => {
      console.log( 'Sign out error',error);
     
    })
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

}
