import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the LimitLinesToPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'limitLinesTo',
})
export class LimitLinesToPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string, ...args) {

    let dots = '';

    let n_lines = args[0];
    let n_chars = args[1]

    let ttt = value.split(/\r?\n/);

    if (value.length > n_chars) dots='...';

    let tt = value.slice(0,n_chars);
    let t = tt.split(/\r?\n/, n_lines);

    if (ttt.length > t.length) dots='...';

    return t.join("\n") + dots; 
  }
}
