/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppDataProvider } from '../../providers/app-data';
import { WpProvider } from '../../providers/wp';
import { AuthenticationService } from '../../providers/authentication/authentication.service';
import { ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  @Input() showMode = 'showCompanyJobs';
  @Input() jobs: any;
  @Input() jobIds: any;
  @Input() jobsAvailable = 0;
  @Input() loading = false;
  @Output() newJob = new EventEmitter<any>();

  loggedUser: any;
  username: any;
  profile: any;
  areas = 'Jobs';
  displayName: string;
  user: any;

  showDetails: any[] = [];

  debug = false;

  jobFields: any[] = [];

  constructor(
    public appData: AppDataProvider,
    public wp: WpProvider,
    public authenticationService: AuthenticationService,
    public tc: ToastController,
    public popoverCtrl: PopoverController,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    console.log('Hello JobsComponent Component');

    this.user = this.appData.getUser();
    if (this.user) {
      this.loggedUser = true;
      this.username = this.user.username;

    }
  }

  ngOnInit() {
    console.log('ngOnInit JobsComponent');
    console.log('Jobs:', this.jobs);
    console.log('jobIds:', this.jobIds);

    for (const jg of this.appData.jobGroupKeys) {
      this.jobFields[jg] = this.appData.getJobFields(jg);
    }
    console.log('jobFields:');
    console.log(this.jobFields);

    if (this.jobs.length === 1) this.showDetails[0] = true;
  }

  isObject(o) {
    return (typeof o === 'object');
  }

  checkFields() {
    for (const j of this.jobs) {
      console.log('j:', j);
      for (const k in j) {
        if (this.isObject(this.jobFields[j.jobGroup][k])) {
          console.log(k);
          const x = this.jobFields[j.jobGroup][k].label;
          console.log('>>>' + x);
        }
      }
    }
  }

  viewJob(id) {
    console.log(id);
    this.appData.navForward('/company-dashboard/view-job', { id, job: this.jobs[id], postId: this.jobIds[id] });
  }

  doProvidersMatched(id) {
    this.appData.navForward('/company-dashboard/providers-matched', { id, job: this.jobs[id], postId: this.jobIds[id] });
  }

  editJob(id) {
    console.log(id);
    this.appData.navForward('/company-dashboard/update-job', { id, job: this.jobs[id], postId: this.jobIds[id] } );
  }

  toggleDetails(i: number) {
    if (!this.showDetails[i] ) {
      this.showDetails[i] = true;
    } else {
      this.showDetails[i] = false;
    }
  }

  doNewJob() {
    this.newJob.emit();
  }

}
