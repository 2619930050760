import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { StorageService} from './storage-service';

import * as Config from '../providers/config';

// import jobFormFieldsBC from '../providers/json/jobForms/jobFormFieldsBC.json';
// import jobFormFieldsBI from '../providers/json/jobForms/jobFormFieldsBI.json';

@Injectable({
  providedIn: 'root'
})

export class AppDataProvider {
  public states: any;

  public role = '';
  public monitize = false;
  public times: UntypedFormGroup;


  constructor(
    // tslint:disable-next-line: variable-name
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public navCtrl: NavController,

    public storage: Storage

  ) {
    // for(let jg of this.jobGroupKeys){
    //  this.jobGroups[jg].jobFields = this.getJobFields(jg)
    //  this.jobGroups[jg].providerFields = this.getProviderFields(jg)
    // }
    console.log('Hello AppDataProvider Provider');
    // console.log(jobFormFieldsBI);
    // console.log(this.jobFormFieldsBI);

    // this.deepEqual(jobFormFieldsBI, this.jobFormFieldsBI);

    this.userData.wpUser = {};
    this.queryParams = [];

    this.init();
  }

  public userData: any = {};
  public queryParams: any;

  public jobGroup = 'Behaviour Interventionist';

  public cities = [
    'East Vancouver', 'South Vancouver', 'Vancouver West', 'South Surrey',
    'North Surrey', 'West Vancouver', 'North Vancouver', 'Langley', 'Burnaby',
    'Port Coquitam', 'New Westminster', 'Richmond', 'Maple Ridge', 'Port Moody', 'Abbottsford',

  ];

  public provinces = [
    'BC', 'AL', 'SK', 'MA', 'ON', 'QU', 'NB', 'NS', 'PE', 'NL'
  ];

  public jobFormFieldsBI: any = {
    sections: [
      // Position Details
      {
        iname: 'jobDetails', name: 'Position Details',
        fields: [
          { name: 'jobName', label: 'Position Name', type: 'text' },
          { name: 'jobActive', label: 'Position Active', type: 'toggle',   yes: 'Yes', no: 'No', meta: 'aba-job-active', val: 1 },
          { name: 'qualifications',  compare: 'equal_or_greater_than_list', label: 'Position', extraLabel: '', type: 'select', multiple: 'false', 
            options: [
              'Behaviour Interventionist', 
              'Senior Bevahioural Interventionist', 
              // 'ABA - Support Worker'
            ], },
          { name: 'location',  compare: 'is_in_options_single', label: ' Location of Position', type: 'select', multiple: 'false', options: this.cities, },
          { name: 'childsAge',  compare: 'is_in_options_single', label: 'Client\'s Age', type: 'select', multiple: 'false', options: ['Under 3 years', '3 to 6 years', '6 to 12 Years', '13 to 18 Years', 'Adult'] },
          { name: 'setting',  compare: 'all_are_in_options', label: 'Position Setting', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Home', 'School', 'Community', 'Agency'] },
          { name: 'preferredCommitment',  compare: 'equal_or_greater_than_list', label: 'Preferred Commitment', extraLabel: '', type: 'select', multiple: 'false', options: ['Less Than One Year', 'One year', 'More Than One Year'] },
          { name: 'language',  compare: 'all_are_in_options', label: 'Preferred Languages', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['English', 'French', 'Cantonese', 'Mandarin', 'Hindi', 'Punjabi', 'Russian', 'Spanish', 'Tagalog'] },
          { name: 'rateOffered',  compare: 'equal_or_more', label: 'Hourly Rate Offered', type: 'dollars' },
          { name: 'negotiable', label: 'Rate Negotiable', yes: 'Negotiable', no: 'Not Negotiable',  type: 'toggle' }
        ],
      },
      // Education & Experience
      {
        iname: 'educationAndExperience', name: 'Education & Experience',
        fields: [
          { name: 'educationAndExperience', label: 'Preferred Education and Experience', type: 'separator' },
          { name: 'experienceLevel',  compare: 'equal_or_greater_than_list', label: 'Experience Level', extraLabel: '(the least exceptable)', type: 'select', multiple: 'false',
            options: ['No Experience', 'One Year', 'Two Years', 'Three or More Years'] },
          { name: 'education',  compare: 'equal_or_greater_than_list', label: 'Education', extraLabel: '(the least exceptable)', type: 'select', multiple: 'false', options: ['High School Diploma', 'Completing Bachelor', 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD'] },
        ],
      },
      // Skills and Requirements
      {
        iname: 'requirementsAndSkills', name: 'Skills and Requirements',
        fields: [
          // Preferred ABA Skill
          { name: 'preferredABASkills', label: 'Preferred ABA Skills', type: 'separator', checkboxes: true },
          { name: 'DataCollection', label: 'Data collection', type: 'checkbox' },
          // { name: "DiscreteTrialTeaching", label: "Discrete Trial Teaching", type: "checkbox" },
          // { name: "NaturalEnvironmentTeaching", label: "Natural Environment Teaching", type: "checkbox" },
          { name: 'Graphing', label: 'Graphing', type: 'checkbox' },
          { name: 'ABATheoryKnowledge', label: 'ABA Theory Training', extraLabel: '(Provided by BCBA)', type: 'checkbox' },

          // General Requirements
          { name: 'generalRequirements', label: 'General Requirements', type: 'separator', checkboxes: true  },
          { name: 'YearsOld19', label: 'At Least 19 Years of Age', type: 'checkbox', },
          { name: 'reliableTransport', label: 'Reliable Form of Transportation', type: 'checkbox', },
          { name: 'strongSocialSkills', label: 'Strong Social Skills', type: 'checkbox', },
          { name: 'strongPlaySkills', label: 'Strong Play Skills', type: 'checkbox', },
          { name: 'strongCommunicationsSkills', label: 'Strong Communications Skills', type: 'checkbox', },
          { name: 'varietyOfReinforcer', label: 'Has a Variety of Toys/Reinforcer', type: 'checkbox', },
          { name: 'takesFeedbackWell', label: 'Takes Feedback Well', type: 'checkbox', },
          { name: 'openToCollaberation', label: 'Open to Collaberation', type: 'checkbox', },
          // { name: "monthlyTeamMeeting", label: "Can Attend Monthly Team Meeting", type: "checkbox", },
          { name: 'criminalBackgroundCheck', label: 'Have a recent criminal background check', type: 'checkbox', },

          // Interests
          { name: 'Interests', label: 'Preferred Interests', type: 'separator', checkboxes: true  },
          { name: 'Swimming', label: 'Swimming', type: 'checkbox' },
          { name: 'Music', label: 'Music', type: 'checkbox' },
          { name: 'Art', label: 'Art', type: 'checkbox' },
          { name: 'Sports', label: 'Sports', type: 'checkbox' },
          { name: 'ComputersAndCoding', label: 'Computers / Coding', type: 'checkbox' },

          // Comments
          { name: 'Comments', label: 'Additional Information', type: 'separator' },
          { name: 'jobDescription', summary: true,  label: 'Additional Information', optional: true, type: 'textarea' },
        ]
      },
    ]
  };
  public providerFormFieldsBI: any = {
    sections: [
      // Provider Profile
      {
        iname: 'ProviderInformation', name: 'Provider Information',
        fields: [
          // Contact Information
          { name: 'Contact Information', label: 'Contact Information', type: 'separator' },
          { name: 'email', label: 'Email', type: 'email', contact: 'true', validate: 'email' },
          // { name: 'phone', label: 'Mobile Phone', type: 'text', allowPrivate: true, contact: 'true', validate: 'phonenumber' },
          // { name: 'streetAddress', label: 'Street', type: 'text', allowPrivate: true, contact: 'true' },
          // { name: 'city', label: 'City', type: 'text', contact: 'true' },
          // { name: 'province', label: 'Province', type: 'select', multiple: 'false', options: this.provinces, contact: 'true' },
          // { name: 'postalCode', label: 'Postal Code', extraLabel: ' (A1A1A1 or A1A 1A1)', type: 'postalcode', contact: 'true', allowPrivate: true, validate: 'postalcode' },
          // { name: 'textNewProviders', label: 'TXT New Job Matches ', td: 'true', type: 'toggle', infoPage: 'mobile-phone-info' },
          // { name: 'emailNewProviders', label: 'Email New Job Matches ', td: 'true', type: 'toggle', infoPage: 'email-info' },

          // Preferences
          { name: 'preferences', label: 'Preferences', type: 'separator' },
          { name: 'qualifications',  compare: 'equal_or_greater_than_list', label: 'Position Preference', type: 'select', multiple: 'false', 
            options: ['Behaviour Interventionist', 
              'Senior Bevahioural Interventionist', 
              // 'ABA - Support Worker'
            ] },
          { name: 'Education and Experience', label: 'Education and Experience', type: 'separator' },
          { name: 'education',  compare: 'equal_or_greater_than_list', label: 'Education', type: 'select', multiple: 'false',
            options: ['High School Diploma', 'Completing Bachelor', 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD'] },
          { name: 'experienceLevel',  compare: 'equal_or_greater_than_list', label: 'Experience Level', type: 'select', multiple: 'false', options: ['No Experience', 'One Year', 'Two Years', 'Three or More Years'] },
          { name: 'language',  compare: 'all_are_in_options', label: 'Languages Spoken', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['English', 'French', 'Cantonese', 'Mandarin', 'Hindi', 'Punjabi', 'Russian', 'Spanish', 'Tagalog'] },
          // { name: "comments", label: "Additional Information", optional: true, type: "textarea" },

        ]
      },
      // Job Preferences
      {
        iname: 'preferences', name: 'Position Preferences',
        fields: [
          { name: 'location',  compare: 'is_in_options_single', label: 'Location Preference', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: this.cities },
          { name: 'childsAge',  compare: 'is_in_options_single', label: 'Client Age Preference', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Under 3 years', '3 to 6 years', '6 to 12 Years', '13 to 18 Years', 'Adult'] },
          { name: 'setting',  compare: 'all_are_in_options', label: 'Setting Preference', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Home', 'School', 'Community', 'Agency'] },
          { name: 'preferredCommitment',  compare: 'equal_or_greater_than_list', label: 'Commitment', extraLabel: '', type: 'select', multiple: 'false', options: ['Less Than One Year', 'One year', 'More Than One Year'] },
          { name: 'rateOffered',  compare: 'equal_or_more', label: 'Preferred Hourly Rate', type: 'dollars' },
          { name: 'negotiable', label: 'Rate Negotiable', yes: 'Negotiable', no: 'Not Negotiable', type: 'toggle' }
        ]
      },
      // ABA Skills and Interests
      {
        iname: 'requirementsAndSkills', name: 'ABA Skills & Interests',
        fields: [

          // Acquired ABA Skills
          { name: 'preferredABASkills', label: 'Acquired ABA Skills', type: 'separator', checkboxes: true  },
          {   name: 'DataCollection', label: 'Data collection', type: 'checkbox' },
          // {   name: "DiscreteTrialTeaching", label: "Discrete Trial Teaching", type: "checkbox" },
          // {   name: "NaturalEnvironmentTeaching", label: "Natural Environment Teaching", type: "checkbox" },
          {   name: 'Graphing', label: 'Graphing', type: 'checkbox' },
          {   name: 'ABATheoryKnowledge', label: 'ABA Theory Training', extraLabel: '(Provided by BCBA)', type: 'checkbox' },

          // General Requirements
          {   name: 'generalRequirements', label: 'General Requirements', type: 'separator', checkboxes: true},
          {   name: 'YearsOld19', label: 'At Least 19 Years of Age', type: 'checkbox' },
          {   name: 'reliableTransport', label: 'Reliable Form of Transportation', type: 'checkbox' },
          {   name: 'strongSocialSkills', label: 'Strong Social Skills', type: 'checkbox' },
          {   name: 'strongPlaySkills', label: 'Strong Play Skills', type: 'checkbox' },
          {   name: 'strongCommunicationsSkills', label: 'Strong Communications Skills', type: 'checkbox' },
          {   name: 'varietyOfReinforcers', label: 'Has a Variety of Toys/Reinforcers', type: 'checkbox' },
          {   name: 'takesFeedbackWell', label: 'Takes Feedback Well', type: 'checkbox' },
          {   name: 'openToCollaberation', label: 'Open to Collaberation', type: 'checkbox' },
          // {   name: "monthlyTeamMeeting", label: "Can Attend Monthly Team Meeting", type: "checkbox", },
          {   name: 'criminalBackgroundCheck', label: 'Have a recent criminal background check', type: 'checkbox', },

          // Interests
          { name: 'Interests', label: 'Interests', type: 'separator', checkboxes: true  },
          {   name: 'Swimming', label: 'Swimming', type: 'checkbox' },
          {   name: 'Music', label: 'Music', type: 'checkbox' },
          {   name: 'Art', label: 'Art', type: 'checkbox' },
          {   name: 'Sports', label: 'Sports', type: 'checkbox' },
          {   name: 'ComputersAndCoding', label: 'Computers / Coding', type: 'checkbox' },

          // Comments
          { name: 'Comments', label: 'Additional Information', type: 'separator' },
          { name: 'comments', label: 'Additional Information', optional: true, type: 'textarea' },
        ]
      },
    ]
  };
  public jobFormFieldsRW: any = {
    sections: [
      // Job Details
      {
        iname: 'jobDetails', name: 'Position Details',
        fields: [

          { name: 'jobName', label: 'Position Name', type: 'text' },
          { name: 'jobActive', label: 'Position Active', type: 'toggle',   yes: 'Yes', no: 'No', meta: 'aba-job-active', val: 1 },

          { name: 'qualifications',  compare: 'equal_or_greater_than_list', label: 'Position', extraLabel: '', type: 'select', multiple: 'false', options: [ 'Respite Worker'], },
          { name: 'location',  compare: 'is_in_options_single', label: ' Location of Job', type: 'select', multiple: 'false', options: this.cities, },
          { name: 'childsAge',  compare: 'is_in_options_single', label: 'Client\'s Age', type: 'select', multiple: 'false', options: ['Under 3 years', '3 to 6 years', '6 to 12 Years', '13 to 18 Years', 'Adult'] },
          { name: 'setting',  compare: 'all_are_in_options', label: 'Position Setting', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Home', 'School', 'Community', 'Agency'] },
          { name: 'preferredCommitment',  compare: 'equal_or_greater_than_list', label: 'Preferred Commitment', extraLabel: '', type: 'select', multiple: 'false', options: ['Less Than One Year', 'One year', 'More Than One Year'] },
          { name: 'language',  compare: 'all_are_in_options', label: 'Preferred Languages', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['English', 'French', 'Cantonese', 'Mandarin', 'Hindi', 'Punjabi', 'Russian', 'Spanish', 'Tagalog'] },
          { name: 'rateOffered',  compare: 'equal_or_more', label: 'Hourly Rate Offered', type: 'dollars' },
          { name: 'negotiable', label: 'Rate Negotiable', yes: 'Negotiable', no: 'Not Negotiable', type: 'toggle' }
        ],
      },
      // Education & Experience
      {
        iname: 'educationAndExperience', name: 'Education & Experience',
        fields: [

          // Preferred Education and Experience
          { name: 'educationAndExperience', label: 'Preferred Education and Experience', type: 'separator' },

          { name: 'experienceLevel',  compare: 'equal_or_greater_than_list', label: 'Preferred Experience Level', extraLabel: '(the least exceptable)', type: 'select', multiple: 'false', options: ['No Experience', 'One Year', 'Two Years', 'Three or More Years'] },
          { name: 'education',  compare: 'equal_or_greater_than_list', label: 'Preferred Education', extraLabel: '(the least exceptable)',
            type: 'select', multiple: 'false', options: ['High School Diploma', 'Completing Bachelor', 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD'] },
        ],
      },
      // Skills and Requirements
      {
        iname: 'requirementsAndSkills', name: 'Skills and Requirements',
        fields: [

          // Preferred Skills
          { name: 'preferredABASkills', label: 'Preferred Skills', type: 'separator', checkboxes: true  },
          { name: 'Feeding', label: 'Feeding', type: 'checkbox' },
          { name: 'Dressing', label: 'Dressing', type: 'checkbox' },
          { name: 'Toileting', label: 'Toileting', type: 'checkbox' },
          { name: 'Bathing', label: 'Bathing', type: 'checkbox' },
          { name: 'RunningErrands', label: 'Running Errands', type: 'checkbox' },
          { name: 'AdministeringMedications', label: 'Administering Medications', type: 'checkbox' },
          { name: 'ActivityPlanning', label: 'Activity Planning', type: 'checkbox' },
          { name: 'LightHousekeeping', label: 'Light Housekeeping', type: 'checkbox' },
          { name: 'Childcare', label: 'Childcare', type: 'checkbox' },
          { name: 'Cooking', label: 'Cooking', type: 'checkbox' },

          // General Requirements
          { name: 'generalRequirements', label: 'General Job Requirements', type: 'separator', checkboxes: true  },
          { name: 'YearsOld19', label: 'At Least 19 Years of Age', type: 'checkbox', },
          { name: 'reliableTransport', label: 'Reliable Form of Transportation', type: 'checkbox', },
          { name: 'FirstAidCPRTraining', label: 'First Aid/CPR Training', type: 'checkbox', },
          { name: 'openToCollaberation', label: 'Open to Collaberation', type: 'checkbox', },
          { name: 'criminalBackgroundCheck', label: 'Have a recent criminal background check', type: 'checkbox', },

          // Comments
          { name: 'Comments', label: 'Additional Information', type: 'separator' },
          { name: 'jobDescription', summary: true, label: 'Additional Information', optional: true, type: 'textarea' },
        ]
      },
    ]
  };
  public providerFormFieldsRW: any = {
    sections: [
      // Provider Profile
      {
        iname: 'ProviderInformation', name: 'Provider Information',
        fields: [

          // Provider Information
          { name: 'Contact Information',  label: 'Contact Information', type: 'separator' },
          { name: 'email', label: 'Email', type: 'email', contact: 'true', validate: 'email'  },
          // { name: 'phone', label: 'Mobile Phone', type: 'text', allowPrivate: true, contact: 'true', validate: 'phonenumber'   },
          // { name: 'streetAddress', label: 'Street', type: 'text', allowPrivate: true, contact: 'true' },
          // { name: 'city', label: 'City', type: 'text', contact: 'true' },
          // { name: 'province', label: 'Province', type: 'select', multiple: 'false', options: this.provinces, contact: 'true' },
          // { name: 'postalCode', label: 'Postal Code', extraLabel: '(A1A1A1 or A1A 1A1)', type: 'text', contact: 'true', allowPrivate: true, validate: 'postalcode' },
          // { name: 'textNewProviders', label: 'TXT New Job Matches ', td: 'true', type: 'toggle', infoPage: 'mobile-phone-info' },
          // { name: 'emailNewProviders', label: 'Email New Job Matches ', td: 'true', type: 'toggle', infoPage: 'email-info' },

          // Preferences
          { name: 'preferences', label: 'Position Preferences', type: 'separator' },
          { name: 'qualifications',  compare: 'equal_or_greater_than_list', label: 'Position', type: 'select', multiple: 'false', options: ['Respite Worker'] },
          { name: 'location',  compare: 'is_in_options_single', label: 'Location of Job', type: 'select', multiple: 'true', options: this.cities },
          { name: 'childsAge',  compare: 'is_in_options_single', label: 'Client Age', type: 'select', multiple: 'true', options: ['Under 3 years', '3 to 6 years', '6 to 12 Years', '13 to 18 Years', 'Adult'] },
          { name: 'setting',  compare: 'all_are_in_options', label: 'Position Setting', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Home', 'School', 'Community', 'Agency'] },
          { name: 'preferredCommitment',  compare: 'equal_or_greater_than_list', label: 'Commitment',  extraLabel: '', type: 'select', multiple: 'false', options: ['Less Than One Year', 'One year', 'More Than One Year'], },
          { name: 'rateOffered',  compare: 'equal_or_more', label: 'Preferred Hourly Rate', type: 'dollars' },
          { name: 'negotiable', label: 'Rate Negotiable', yes: 'Negotiable', no: 'Not Negotiable', type: 'toggle' },
          // { name: "comments", label: "Additional Information", optional: true, type: "textarea" },
        ]
      },

      {
        iname: 'EducationandExperience', name: 'Education and Experience',
        fields: [
          { name: 'Education and Experience', label: 'Education and Experience', type: 'separator'  },
          { name: 'education',  compare: 'equal_or_greater_than_list', label: 'Education', type: 'select', multiple: 'false', options: ['High School Diploma',
            'Completing Bachelor', 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD'], weight: 10          },
          { name: 'experienceLevel',  compare: 'equal_or_greater_than_list', label: 'Experience Level', type: 'select', multiple: 'false', options: ['No Experience', 'One Year', 'Two Years', 'Three or More Years'], weight: 30          },
          { name: 'language',  compare: 'all_are_in_options', label: 'Languages Spoken', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['English', 'French', 'Cantonese', 'Mandarin', 'Hindi', 'Punjabi', 'Russian', 'Spanish', 'Tagalog'], weight: 100          },
        ]
      },
      // Skills and Requirements
      {
        iname: 'requirementsAndSkills', name: 'Skills and Requirements',
        fields: [

          // Preferred Skills
          { name: 'preferredABASkills', label: 'Acquired Respite Skills', type: 'separator', checkboxes: true },

          {name: 'Feeding', label: 'Feeding', type: 'checkbox' },
          {name: 'Dressing', label: 'Dressing', type: 'checkbox' },
          {name: 'Toileting', label: 'Toileting', type: 'checkbox' },
          {name: 'Bathing', label: 'Bathing', type: 'checkbox' },
          {name: 'RunningErrands', label: 'Running Errands', type: 'checkbox' },
          {name: 'AdministeringMedications', label: 'Administering Medications', type: 'checkbox' },
          {name: 'ActivityPlanning', label: 'Activity Planning', type: 'checkbox' },
          {name: 'LightHousekeeping', label: 'Light Housekeeping', type: 'checkbox' },
          {name: 'Childcare', label: 'Childcare', type: 'checkbox' },
          {name: 'Cooking', label: 'Cooking', type: 'checkbox' },

          // General Requirements
          { name: 'generalRequirements', label: 'General Requirements', type: 'separator', checkboxes: true  },

          {name: 'YearsOld19', label: 'At Least 19 Years of Age', type: 'checkbox', },
          {name: 'reliableTransport', label: 'Reliable Form of Transportation', type: 'checkbox', },
          {name: 'FirstAidCPRTraining', label: 'First Aid/CPR Training', type: 'checkbox', },
          { name: 'openToCollaberation', label: 'Open to collaboration with other professionals', type: 'checkbox', },
          { name: 'criminalBackgroundCheck', label: 'Have a recent criminal background check', type: 'checkbox', },

          // Comments
          { name: 'Comments', label: 'Additional Information', type: 'separator' },
          { name: 'comments', label: 'Additional Information', optional: true, type: 'textarea' },

        ]
      },
    ]
  };
  public jobFormFieldsBC: any = {
    sections: [
      // Provider Profile
      {
        iname: 'ProviderInformation', name: 'Provider Information',
        fields: [

          { name: 'jobName', label: 'Position Name', type: 'text' },
          { name: 'jobActive', label: 'Position Active', type: 'toggle',   yes: 'Yes', no: 'No', meta: 'aba-job-active', val: 1 },

          // Contact Information
          // { name: "Contact Information", label: "Contact Information", type: "separator" },

          // { name: "qualifications", label: "Qualifications", type: "select", multiple: "false", options: ["Behaviour Consultant"] },
          // { name: "email", label: "Email", type: "text" },
          // { name: "phone", label: "Phone", type: "text" },
          // { name: "streetAddress", label: "Street", type: "text" },
          // { name: "city", label: "City", type: "text" },
          // { name: "postalCode", label: "Postal Code", type: "text" },

          // Education and Experience
          { name: 'Required Education and Experience', label: 'Education and Experience', type: 'separator' },

          { name: 'experienceLevel',  compare: 'equal_or_greater_than_list', label: 'Required Experience Level', type: 'select', multiple: 'false', options: ['Less Than 3 Years', '3 to 5 Years', '6 to 10 Years', 'More Than 10 Years'] },
          { name: 'education',  compare: 'equal_or_greater_than_list', label: 'Required Education', type: 'select', multiple: 'false', options: ['Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD'] },
          { name: 'certification',  compare: 'equal_or_greater_than_list', label: 'Preferred Consultant Certification', type: 'select', multiple: 'false',
            options: ['No Certification', 'BCaBA', 'BCBA', 'BCBA - D'],
            infoPage: 'bc-certification'
          },
          { name: 'registeredProvider',  compare: 'equal_or_greater_than_list', label: 'Preferred Consultant Registration',
            extraLabel: '(Ministry RASP List)', type: 'select', multiple: 'false',
          options: ['Not on RASP list', 'RASP List Category B', 'RASP List Category A'],
            infoPage: 'rasp-list'
          },

          { name: 'language',  compare: 'all_are_in_options', label: 'Languages Preferred', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['English', 'French', 'Cantonese', 'Mandarin', 'Hindi', 'Punjabi', 'Russian', 'Spanish', 'Tagalog'] },

          // Availablity
          { name: 'availability', label: 'Required Availablity', type: 'separator', checkboxes: true },
          { name: 'morning', label: 'Daytime', type: 'checkbox' },
          { name: 'afterSchool', label: 'After School', type: 'checkbox' },

        ]
      },
      // Job Preferences
      {
        iname: 'preferences', name: 'Position Preferences',
        fields: [
          { name: 'location',  compare: 'is_in_options_single', label: 'Location of Job', extraLabel: '', type: 'select', multiple: 'false', options: this.cities },
          { name: 'childsAge',  compare: 'is_in_options_single', label: 'Client\'s Age', extraLabel: '', type: 'select', multiple: 'false', options: ['Under 3 years', '3 to 6 years', '6 to 12 Years', '13 to 18 Years', 'Adult'] },
          { name: 'setting',  compare: 'all_are_in_options', label: 'Position Settings', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Home', 'School', 'Community', 'Agency'] },
          // { name: "preferredCommitment", label: "Commitment", extraLabel: "", type: "select",
          // multiple: "false", options: ["Less Than One Year", "One year", "More Than One Year"] },
          { name: 'rateOffered',  compare: 'equal_or_more', label: 'Hourly Rate Offered', type: 'dollars' },
          { name: 'negotiable', label: 'Rate Negotiable', yes: 'Negotiable', no: 'Not Negotiable', type: 'toggle' }
        ]
      },
      // ABA Skills and Interests
      {
        iname: 'requirementsAndSkills', name: 'Specialization & Interests',
        fields: [
          // Acquired ABA Skills
          { name: 'preferredABASkills', label: 'Specialization Required', type: 'separator', checkboxes: true },

          { name: 'languageAndCommunication', label: 'Language and Communication', type: 'checkbox' },
          { name: 'socialSkills', label: 'Social Skills', type: 'checkbox' },
          { name: 'sleep', label: 'Sleep', type: 'checkbox' },
          { name: 'eating', label: 'Eating', type: 'checkbox' },
          { name: 'mobility', label: 'Mobility', type: 'checkbox' },
          { name: 'pubertySexualEducation', label: 'Puberty/Sexual Education', type: 'checkbox' },
          { name: 'toiletTraining', label: 'Toilet Training', type: 'checkbox' },
          { name: 'lifeSkills', label: 'Life Skills', type: 'checkbox' },
          { name: 'aggression', label: 'Aggression', type: 'checkbox' },
          { name: 'selfInjuriousBehavior', label: 'Self-Injurious Behaviour', type: 'checkbox' },

          // ABA Treatment Type
          { name: 'treatmentType', label: 'ABA Treatment Type', extraLabel: '(If unsure, select all)',
            type: 'separator', checkboxes: true },
          { name: 'dtt', label: 'Discrete Trial Teaching (DTT)', type: 'checkbox' },
          { name: 'net', label: 'Natural Environment Teaching ', type: 'checkbox' },
          { name: 'fct', label: 'Functional Communication Training (FCT)', type: 'checkbox' },
          { name: 'vb', label: 'Verbal Behaviour (VB)', type: 'checkbox' },
          { name: 'prt', label: 'Pivotal Response Training (PRT)', type: 'checkbox' },
          { name: 'pt', label: 'Precision Teaching (PT)', type: 'checkbox' },
          { name: 'esdm', label: 'Early Start Denver Model (ESDM)', type: 'checkbox' },
          { name: 'abi', label: 'Antecedent-Based Interventions', type: 'checkbox' },

          // Interests
          // { name: "Interests", label: "Preferred Interests", type: "separator", checkboxes: true  },

          // { name: "Swimming", label: "Swimming", type: "checkbox" },
          // { name: "Music", label: "Music", type: "checkbox" },
          // { name: "Art", label: "Art", type: "checkbox" },
          // { name: "Sports", label: "Sports", type: "checkbox" },
          // { name: "ComputersAndCoding", label: "Computers / Coding", type: "checkbox" },

          // Comments
          { name: 'Comments', label: 'Additional Information', type: 'separator' },
          { name: 'comments', summary: true, label: 'Additional Information', optional: true, type: 'textarea' },
        ]
      },
    ]
  };
  public providerFormFieldsBC: any = {
    sections: [
      // Provider Profile
      {
        iname: 'ProviderInformation', name: 'Provider Information',
        fields: [
          // Contact Information
          { name: 'Contact Information', label: 'Contact Information', type: 'separator' },

          // { name: "qualifications", label: "Qualifications", type: "select", multiple: "false", options: ["Behaviour Consultant"] },
          { name: 'email', label: 'Email', type: 'email', contact: 'true', validate: 'email' },
          // { name: 'phone', label: 'Mobile Phone', type: 'text', allowPrivate: true, contact: 'true', validate: 'phonenumber' },
          // { name: 'streetAddress', label: 'Street', type: 'text', allowPrivate: true, contact: 'true' },
          // { name: 'city', label: 'City', type: 'text', contact: 'true' },
          // { name: 'province', label: 'Province', type: 'select', multiple: 'false', options: this.provinces, allowPrivate: true, contact: 'true'},
          // { name: 'postalCode', label: 'Postal Code', extraLabel: ' (A1A1A1  or A1A 1A1)', type: 'text', contact: 'true', allowPrivate: true, validate: 'postalcode' },
          // { name: 'textNewProviders', label: 'TXT New Job Matches ', td: 'true', type: 'toggle', infoPage: 'mobile-phone-info', hideRo: true },
          // { name: 'emailNewProviders', label: 'Email New Job Matches ', td: 'true', type: 'toggle', infoPage: 'email-info', hideRo: true },

          // Education and Experience
          { name: 'Education and Experience', label: 'Education and Experience', type: 'separator' },

          { name: 'education',  compare: 'equal_or_greater_than_list', label: 'Education', type: 'select', multiple: 'false', options: [ 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD'] },
          { name: 'experienceLevel',  compare: 'equal_or_greater_than_list', label: 'Experience Level', type: 'select', multiple: 'false', options: ['Less Than 3 Years', '3 to 5 Years', '6 to 10 Years', 'More Than 10 Years'] },
          { name: 'certification',  compare: 'equal_or_greater_than_list', label: 'Certification', type: 'select', multiple: 'false', options: ['No Certification', 'BCBA', 'BCaBA', 'BCBA - D'] },
          { name: 'registeredProvider',  compare: 'equal_or_greater_than_list', label: 'Registered Provider', extraLabel: '(Ministry RASP List)',
            type: 'select', multiple: 'false', options: ['Not on RASP list', 'RASP List Category A', 'RASP List Category B' ] },

          { name: 'language',  compare: 'all_are_in_options', label: 'Languages Spoken', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['English', 'French', 'Cantonese', 'Mandarin', 'Hindi', 'Punjabi', 'Russian', 'Spanish', 'Tagalog'] },

        ]
      },
      // Job Preferences
      {
        iname: 'preferences', name: 'Position Preferences',
        fields: [
          { name: 'location',  compare: 'is_in_options_single', label: 'Location Preference', extraLabel: '(all that apply)',
            type: 'select', multiple: 'true', options: this.cities },
          { name: 'childsAge',  compare: 'is_in_options_single', label: 'Client Age Preference', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Under 3 years', '3 to 6 years', '6 to 12 Years', '13 to 18 Years', 'Adult'] },
          { name: 'setting',  compare: 'all_are_in_options', label: 'Setting Preference', extraLabel: '(all that apply)', type: 'select', multiple: 'true', options: ['Home', 'School', 'Community', 'Agency'] },
          // { name: "preferredCommitment", label: "Commitment", extraLabel: "", type: "select",
          // multiple: "false", options: ["Less Than One Year", "One year", "More Than One Year"] },
          { name: 'rateOffered',  compare: 'equal_or_more', label: 'Preferred Hourly Rate', type: 'dollars' },
          { name: 'negotiable', label: 'Rate Negotiable', yes: 'Negotiable', no: 'Not Negotiable',  type: 'toggle' },

          // Availablity
          { name: 'availability', label: 'Availablity', type: 'separator', checkboxes: true },
          { name: 'morning', label: 'Daytime', type: 'checkbox' },
          { name: 'afterSchool', label: 'After School', type: 'checkbox' },

          // ABA Treatment Type
          { name: 'treatmentType', label: 'ABA Treatment Type', type: 'separator', checkboxes: true },
          { name: 'dtt', label: 'Discrete Trial Teaching (DTT)', type: 'checkbox' },
          { name: 'net', label: 'Natural Environment Teaching ', type: 'checkbox' },
          { name: 'fct', label: 'Functional Communication Training (FCT)', type: 'checkbox' },
          { name: 'vb', label: 'Verbal Behaviour (VB)', type: 'checkbox' },
          { name: 'prt', label: 'Pivotal Response Training (PRT)', type: 'checkbox' },
          { name: 'pt', label: 'Precision Teaching (PT)', type: 'checkbox' },
          { name: 'esdm', label: 'Early Start Denver Model (ESDM)', type: 'checkbox' },
          { name: 'abi', label: 'Antecedent-Based Interventions', type: 'checkbox' },
        ]
      },
      // ABA Skills and Interests
      {
        iname: 'requirementsAndSkills', name: 'Specialization & Interests',
        fields: [
          // Acquired ABA Skills
          { name: 'preferredABASkills', label: 'Specialization', type: 'separator', checkboxes: true  },

          { name: 'languageAndCommunication', label: 'Language and Communication', type: 'checkbox' },
          { name: 'socialSkills', label: 'Social Skills', type: 'checkbox' },
          { name: 'sleep', label: 'Sleep', type: 'checkbox' },
          { name: 'eating', label: 'Eating', type: 'checkbox' },
          { name: 'mobility', label: 'Mobility', type: 'checkbox' },
          { name: 'pubertySexualEducation', label: 'Puberty/Sexual Education', type: 'checkbox' },
          { name: 'toiletTraining', label: 'Toilet Training', type: 'checkbox' },
          { name: 'lifeSkills', label: 'Life Skills', type: 'checkbox' },
          { name: 'aggression', label: 'Aggression', type: 'checkbox' },
          { name: 'selfInjuriousBehavior', label: 'Self-Injurious Behaviour', type: 'checkbox' },

          // Interests
          // { name: "Interests", label: "Prefered Interests", type: "separator", checkboxes: true  },

          // { name: "Swimming", label: "Swimming", type: "checkbox" },
          // { name: "Music", label: "Music", type: "checkbox" },
          // { name: "Art", label: "Art", type: "checkbox" },
          // { name: "Sports", label: "Sports", type: "checkbox" },
          // { name: "ComputersAndCoding", label: "Computers / Coding", type: "checkbox" },

          // Comments
          { name: 'Comments', label: 'Additional Information', type: 'separator' },
          { name: 'comments',  label: 'Additional Information', optional: true, type: 'textarea' },
        ]
      },
    ]
  };


  public jobGroupsMulti: any = {

    'Behaviour Interventionist': {
      jobs: [
        'Behaviour Interventionist', 
        'Senior Bevahioural Interventionist', 
      //  'ABA Support Worker'
      ],
      jobFF: this.jobFormFieldsBI,
      providerFF: this.providerFormFieldsBI,
      calendar: 'full'

    },

    'Respite Worker': {
      jobs: ['Respite Worker'],
      jobFF: this.jobFormFieldsRW,
      providerFF: this.providerFormFieldsRW,
      calendar: 'full'

    },

    'Behaviour Consultant': {
      jobs: ['Behaviour Consultant'],
      jobFF: this.jobFormFieldsBC,
      providerFF: this.providerFormFieldsBC,
      calendar: 'none'
    }

  };

  public jobGroups: any = {

    'Behaviour Interventionist': {
      jobs: [
        'Behaviour Interventionist', 
        'Senior Bevahioural Interventionist', 
        // 'ABA Support Worker'
      ],
      jobFF: this.jobFormFieldsBI,
      providerFF: this.providerFormFieldsBI,
      calendar: 'full'

    },

  };

  public jobGroupKeys = Object.keys(this.jobGroups);

  public providerSections: any = {
    BI: {
      sections: [
        // Education & Experience
        {
          iname: 'educationAndExperience',
          name: 'Education & Experience',
          fields: [
            {
              name: 'educationAndExperience',
              label: 'Preferred Education and Experience',

              type: 'separator'
            },
            {
              name: 'education',
              label: 'Education',
              extraLabel: '(the least exceptable)',
              td: ['Masters'],
              type: 'select',
              multiple: 'false',
              options: ['High School Diploma', 'Completing Bachelor', 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD']
            },

            {
              name: 'experienceLevel',
              label: 'Experience Level',
              extraLabel: '(the least exceptable)',
              td: ['One Year'],
              type: 'select',
              multiple: 'false',
              options: ['No Experience', 'One Year', 'Two Years', 'Three or More Years']
            },
          ],
        },
        // Skills and Requirements
        {
          iname: 'requirementsAndSkills',
          name: 'Skills and Requirements',
          fields: [
            {
              name: 'preferredABASkills',
              label: 'Preferred ABA Skills',

              type: 'separator'
            },
            {
              name: 'DataCollection',
              label: 'Data collection',

              type: 'checkbox'
            },
            {
              name: 'DiscreteTrialTeaching',
              label: 'Discrete Trial Teaching',

              type: 'checkbox'
            },
            {
              name: 'NaturalEnvironmentTeaching',
              label: 'Natural Environment Teaching',

              type: 'checkbox'
            },
            {
              name: 'Graphing',
              label: 'Graphing',

              type: 'checkbox'
            },
            {
              name: 'ABATheoryKnowledge',
              label: 'ABA Theory Training',
              extraLabel: '(Provided by BCBA)',

              type: 'checkbox'
            },
            {
              name: 'generalRequirements',
              label: 'General Requirements',

              type: 'separator'
            },
            {
              name: 'YearsOld19',
              label: 'At Least 19 Years of Age',

              type: 'checkbox',
            },
            {
              name: 'reliableTransport',
              label: 'Reliable Form of Transportation',

              type: 'checkbox',
            },
            {
              name: 'strongSocialSkills',
              label: 'Strong Social Skills',

              type: 'checkbox',
            },
            {
              name: 'strongPlaySkills',
              label: 'Strong Play Skills',

              type: 'checkbox',
            },
            {
              name: 'strongCommunicationsSkills',
              label: 'Strong Communications Skills',

              type: 'checkbox',
            },
            {
              name: 'varietyOfReinforcer',
              label: 'Has a Variety of Toys/Reinforcer',

              type: 'checkbox',
            },
            {
              name: 'takesFeedbackWell',
              label: 'Takes Feedback Well',

              type: 'checkbox',
            },
            {
              name: 'openToCollaberation',
              label: 'Open to Collaberation',

              type: 'checkbox',
            },
            // { name: "monthlyTeamMeeting", label: "Can Attend Monthly Team Meeting", type: "checkbox", },
            { name: 'criminalBackgroundCheck', label: 'Have a recent criminal background check', type: 'checkbox', },
            {
              name: 'Interests',
              label: 'Interests',

              type: 'separator'
            },
            {
              name: 'Swimming',
              label: 'Swimming',

              type: 'checkbox',
              weight: 10
            },
            {
              name: 'Music',
              label: 'Music',

              type: 'checkbox',
              weight: 10
            },
            {
              name: 'Art',
              label: 'Art',

              type: 'checkbox',
              weight: 10
            },
            {
              name: 'Sports',
              label: 'Sports',

              type: 'checkbox',
              weight: 10
            },
            {
              name: 'ComputersAndCoding',
              label: 'Computers / Coding',

              type: 'checkbox',
              weight: 10
            },
            {
              name: 'jobDescription',
              label: 'Additional Information',
              optional: true,
              td: [''],
              type: 'textarea'
            },
          ]
        },
      ],
      availability: true,
    },

    RW: {
      sections: [
        // Education & Experience
        {
          iname: 'educationAndExperience',
          name: 'Education & Experience',
          fields: [
            {
              name: 'educationAndExperience',
              label: 'Preferred Education and Experience',

              type: 'separator'
            },
            {
              name: 'education',
              label: 'Education',
              extraLabel: '(the least exceptable)',
              td: ['Masters'],
              type: 'select',
              multiple: 'false',
              options: ['High School Diploma', 'Completing Bachelor', 'Bachelor', 'Completing Masters', 'Masters', 'Completing PHD', 'PHD']
            },

            {
              name: 'experienceLevel',
              label: 'Experience Level',
              extraLabel: '(the least exceptable)',
              td: ['One Year'],
              type: 'select',
              multiple: 'false',
              options: ['No Experience', 'One Year', 'Two Years', 'Three or More Years']
            },
          ],
        },
        // Skills and Requirements
        {
          iname: 'requirementsAndSkills',
          name: 'Skills and Requirements',
          fields: [
            // Preferred Skills
            {
              name: 'preferredSkills',
              label: 'Preferred Skills',

              type: 'separator'
            },
            {
              name: 'Feeding',
              label: 'Feeding',

              type: 'checkbox'
            },
            {
              name: 'Dressing',
              label: 'Dressing',

              type: 'checkbox'
            },
            {
              name: 'Toileting',
              label: 'Toileting',

              type: 'checkbox'
            },
            {
              name: 'Bathing',
              label: 'Bathing',

              type: 'checkbox'
            },
            {
              name: 'RunningErrands',
              label: 'Running Errands',

              type: 'checkbox'
            },
            {
              name: 'AdministeringMedications',
              label: 'Administering Medications',

              type: 'checkbox'
            },
            {
              name: 'ActivityPlanning',
              label: 'Activity Planning',

              type: 'checkbox'
            },
            {
              name: 'LightHousekeeping',
              label: 'Light Housekeeping',

              type: 'checkbox'
            },
            {
              name: 'Childcare',
              label: 'Childcare',

              type: 'checkbox'
            },
            {
              name: 'Cooking',
              label: 'Cooking',

              type: 'checkbox'
            },
            { name: 'criminalBackgroundCheck', label: 'Have a recent criminal background check', type: 'checkbox', },

            // General Requirements
            {
              name: 'generalRequirements',
              label: 'General Requirements',

              type: 'separator'
            },
            {
              name: 'YearsOld19',
              label: 'At Least 19 Years of Age',

              type: 'checkbox',
            },
            {
              name: 'reliableTransport',
              label: 'Reliable Form of Transportation',

              type: 'checkbox',
            },
            {
              name: 'FirstAidCPRTraining',
              label: 'First Aid/CPR Training',

              type: 'checkbox',
            },
            {
              name: 'openToCollaberation',
              label: 'Open to Collaberation',

              type: 'checkbox',
            },
            {
              name: 'jobDescription',
              label: 'Additional Information',
              optional: true,
              td: [''],
              type: 'textarea'
            },
          ]
        },
      ],
      availablility: true,
    }
  };

  public companyProfileFormFields: any = {
    sections: [
      {
        iname: 'contactInformation',
        name: 'Agency Information',
        fields: [
          { name: 'contactName', label: 'Contact Name', type: 'text', contact: 'true'},
          { name: 'email', label: 'Email', type: 'email', contact: 'true', verify: true, validate: 'email'  },
          // { name: 'companyPhone', label: 'Phone', type: 'text', allowPrivate: true, optional: true, contact: 'true', validate: 'phonenumber'  },
          // { name: 'mobilePhone', label: 'Mobile Phone', extraLabel: '', td: '250 555 5555', type: 'text', allowPrivate: true, contact: 'true', verify: true, validate: 'phonenumber'  },
          // { name: 'streetAddress', label: 'Street', td: 'Test Street', type: 'text', allowPrivate: true, contact: 'true'},
          // { name: 'city', label: 'City', td: 'Testville BC', type: 'text', contact: 'true'},
          // { name: 'province', label: 'Province', type: 'select', multiple: 'false', options: this.provinces, contact: 'true' },
          // { name: 'postalCode', label: 'Postal Code', extraLabel: ' (A1A1A1 or A1A 1A1)', td: 'V0G 1H0', type: 'text', contact: 'true', allowPrivate: true, validate: 'postalcode'},
          // { name: 'textNewProviders', label: 'TXT New Provider Matches ', td: 'true', type: 'toggle', infoPage: 'mobile-phone-info', hideRo: true },
          // { name: 'emailNewProviders', label: 'Email New Provider Matches ', td: 'true', type: 'toggle',infoPage: 'email-info', hideRo: true },
        ]
      }
    ]
  };

  public familyProfileFormFields: any = {
    sections: [
      {
        iname: 'contactInformation',
        name: 'Family Information',
        fields: [
          { name: 'contactName', label: 'Contact Name', td: 'Contact Name', type: 'text', contact: 'true' },
          { name: 'email', label: 'Email', type: 'email', contact: 'true', verify: true, validate: 'email'   },
          // { name: 'contactPhone', label: 'Contact Phone', td: '250 555 5555', type: 'text', allowPrivate: true, contact: 'true', validate: 'phonenumber' },
          // { name: 'mobilePhone', label: 'Mobile Phone', extraLabel: '', td: '250 555 5555', type: 'text', allowPrivate: true, contact: 'true', verify: true, validate: 'phonenumber'  },
          // { name: 'streetAddress', label: 'Street', td: 'Test Street', type: 'text', allowPrivate: true, contact: 'true'},
          // { name: 'city', label: 'City', td: 'Testville BC', type: 'text', contact: 'true'},
          // { name: 'province', label: 'Province', type: 'select', multiple: 'false', options: this.provinces, contact: 'true' },
          // { name: 'postalCode', label: 'Postal Code', extraLabel: '(A1A1A1 or A1A 1A1)', td: 'V0G 1H0', type: 'text', contact: 'true', allowPrivate: true, validate: 'postalcode' },
          // { name: 'textNewProviders', label: 'TXT New Provider Matches ', td: 'true', type: 'toggle', infoPage: 'mobile-phone-info', hideRo: true },
          // { name: 'emailNewProviders', label: 'Email New Provider Matches ', td: 'true', type: 'toggle', infoPage: 'email-info', hideRo: true },
        ]
      }
    ]
  };

  public subscriptionFormFields: any = {
    sections: [
      {
        iname: 'subscription',
        name: 'Subscription',
        fields: [
          {
            name: 'numberOfJobs',
            label: 'Number of Jobs',
            td: 'Number of Jobs',
            type: 'counter-input',
            val: 1,
            max: 10,
            min: 1,
            incr: 1,
          },
          {
            name: 'numberOfDays',
            label: 'Number of Days',
            td: 'Number of Days',
            type: 'counter-input',
            val: 30,
            max: 120,
            min: 30,
            incr: 30,
          },
        ]
      }
    ]
  };

  public weekDays: { day: string}[] = [
    {day: 'Sun'},
    {day: 'Mon'},
    {day: 'Tue'},
    {day: 'Wed'},
    {day: 'Thur'},
    {day: 'Fri'},
    {day: 'Sat'},

  ];

  public dayTimes: {time: string}[] = [
    {time: '6 AM'},
    {time: '7 AM'},
    {time: '8 AM'},
    {time: '9 AM'},
    {time: '10 AM'},
    {time: '11 AM'},
    {time: '12 PM'},
    {time: '1 PM'},
    {time: '2 PM'},
    {time: '3 PM'},
    {time: '4 PM'},
    {time: '5 PM'},
    {time: '6 PM'},
    {time: '7 PM'},
    {time: '8 PM'},
    {time: '9 PM'},
    { time: '10 PM' },
    { time: '11 PM' },
    { time: '12 PM' },
    { time: '1 AM' },
    { time: '2 AM' },
    { time: '3 AM' },
    { time: '4 AM' },
    { time: '5 AM' },

  ];

  async init() {
    await this.storage.create();
  }

  async storeEncypted(key: string, value: any) {
    const encryptedValue = btoa(encodeURI((JSON.stringify(value))));
    return await this.storage.set(key , encryptedValue);
  }
  async getEncrypted(key: string ) {
    return new Promise(resolve => {
      this.storage.get(key).then( (value) => {
        if ( value == null)  {
          resolve(false);
        } else {
          resolve(JSON.parse(decodeURI(atob(value))));
        }
      });
    });
  }

  public async setState(page, state) {
    const encryptedValue = btoa(encodeURI((JSON.stringify(state))));
    return await this.storage.set(page , encryptedValue);
  }
  public async getState(page) {
    return new Promise(resolve => {
      this.storage.get(page).then( (value) => {
        if ( value == null)  {
          resolve(false);
        } else {
          resolve(JSON.parse(decodeURI(atob(value))));
        }
      });
    });
  }

  public getUser() {
    return this.userData;
  }

  public getUserFromStorage(): Promise<any> {
    return this.getEncrypted('userData');
  }

  public setUser(user: any) {
    this.userData = user;
    this.storeEncypted('userData', user);
  }

  buildForm(fg: UntypedFormGroup, ff: any) {
    this.buildForm_new(fg, ff);
  }

  buildForm_new(fg: UntypedFormGroup, ff: any) {
    let validations: ValidatorFn[] = [];

    for (const section of ff.sections) {
      for (const field of section.fields) {
        validations = [];
        if (field.type !== 'separator') {

          if (field.validate && field.type === 'text') {

            if (field.validate === 'email') {
              validations.push(Validators.email);
            }
            if (field.validate === 'phonenumber') {
              const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
              validations.push(Validators.pattern(PHONE_REGEXP));
            }
            if (field.validate === 'postalcode') {
              validations.push(Validators.pattern('[A-Za-z][0-9][A-Za-z][- ]?[0-9][A-Za-z][0-9]'));
            }
          }

          if (field.validate && field.type === 'email') {
            if (field.validate === 'email') {
              validations.push(Validators.email);
            }
          }

          if (field.validate && field.type === 'postalcode') {
            if (field.validate === 'postalcode') {
              validations.push(Validators.pattern('[A-Za-z][0-9][A-Za-z][- ]?[0-9][A-Za-z][0-9]'));
            }
          }

          if (!field.optional) {
            validations.push(Validators.required);
          }

          if (field.type === 'checkbox' || field.type === 'toggle') {
            fg.addControl(field.name, new UntypedFormControl( field.val ));

          } else if (field.type === 'counter-input') {
            fg.addControl(field.name, new UntypedFormControl(field.val, Validators.required));

          } else if (field.type === 'text') {
            if (field.allowPrivate) {
              fg.addControl(field.name + '_private', new UntypedFormControl(false));
            }
            fg.addControl(field.name, new UntypedFormControl(field.val, validations));

          } else if (field.type === 'dollars') {
            const CURR_REGEXP = '^[0-9]+(\.[0-9]{1,2})?$';
            validations.push(Validators.pattern(CURR_REGEXP));
            fg.addControl(field.name, new UntypedFormControl(field.val, validations));

          } else if (field.type === 'select') {
            if (field.type === 'select' && field.options.length === 1) {
              field.val = field.options[0];
            }
            fg.addControl(field.name, new UntypedFormControl(field.val));

          } else {
            fg.addControl(field.name, new UntypedFormControl(field.val, validations));
          }

        }
      }
    }
  }

  buildForm_old(fg: UntypedFormGroup, ff: any) {
    const validations: ValidatorFn[] = [];

    for (const section of ff.sections) {
      for (const field of section.fields) {
        if (field.type !== 'separator') {

          if (field.validate && field.type === 'text') {

            if (!field.optional) {
              validations.push(Validators.required);
            }
            if (field.validate === 'email') {
              validations.push(Validators.email);
            }
            if (field.validate === 'phonenumber') {
              validations.push(Validators.pattern('[0-9]{0-10}'));
            }
            if (field.validate === 'postalcode') {
              validations.push(Validators.pattern('[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]'));
              if (field.allowPrivate) {
                fg.addControl(field.name + '_private', new UntypedFormControl(false));
              }
            }
          }

          if (field.type === 'checkbox' || field.type === 'toggle') {
            fg.addControl(field.name, new UntypedFormControl(field.val));

          } else if (field.type === 'counter-input') {
            fg.addControl(field.name, new UntypedFormControl(field.val, Validators.required));

          } else {
            if (field.allowPrivate) {
              fg.addControl(field.name + '_private', new UntypedFormControl(false));

            }
            if (field.optional) {
              fg.addControl(field.name, new UntypedFormControl(field.val));

            } else {
              if (field.type === 'select' && field.options.length === 1) {
                field.val = field.options[0];

              }
              fg.addControl(field.name, new UntypedFormControl(field.val, Validators.required));

            }
          }
        }
      }
    }
  }

  public getJobFields(jobGroup: any) {
    let chkSection = '';

    const jg = this.jobGroups[jobGroup];
    const ff = jg.jobFF;
    const fields = [];

    for (const section of ff.sections) {
      for (const field of section.fields) {
        if (field.checkboxes) {
          chkSection = field.name;
          // fields[field.name] = field;
          fields.push(field);
          // console.log(field.type, field.name);
        } else {
          if (field.type !== 'checkbox') { chkSection = ''; }
          if (chkSection !== '') { field.chkName = chkSection; }
          // fields[field.name] = field;
          fields.push(field);
          // console.log(field.type, field.name);
        }
      }
    }
    return fields;
  }

  public getFormFields(ff: any) {
    let chkSection = '';
    const fields = [];

    for (const section of ff.sections) {
      for (const field of section.fields) {

        if (field.checkboxes) {

          chkSection = field.name;
          fields.push(field);
          // console.log(field.type, field.name);

        } else {
          if (field.type !== 'checkbox') {
            chkSection = '';
          }
          if (chkSection !== '') {
            field.chkName = chkSection;
          }
          fields.push(field);
          // console.log(field.type, field.name);
        }
      }

    }
    return fields;
  }

  public getProviderFields(jobGroup: any) {
    let chkSection = '';

    const jg = this.jobGroups[jobGroup];
    const ff = jg.providerFF;
    const fields: { [id: string]: any; } = {};

    for (const section of ff.sections) {
      for (const field of section.fields) {
        if (field.checkboxes) {
          chkSection = field.name;
          fields[field.name] = field;
        } else {
          if (field.type !== 'checkbox') { chkSection = ''; }
          if (chkSection !== '') { field.chkName = chkSection; }
          fields[field.name] = field;
        }
      }
    }
    return fields;
  }

  public getJobFormFields(jobGroup: any) {
    return this.jobGroups[jobGroup].jobFF;
  }

  public getProviderFormFields(jobGroup: any) {
    return this.jobGroups[jobGroup].providerFF;
  }

  addSchedule(fg: UntypedFormGroup) {
    fg.addControl('schedule', this.formBuilder.array([]));
    this.times = this.formBuilder.group({});
    this.times.addControl('times', this.formBuilder.array([]));
    const control = fg.controls['schedule'] as UntypedFormArray;

    // tslint:disable-next-line: variable-name
    for (const _t of this.dayTimes) {
      control.push(this.formBuilder.group({
        days: this.buildDays()
      }));
    }
  }

  addWeekDaysSchedule(fg: UntypedFormGroup) {
    fg.addControl('schedule', this.formBuilder.array([]));
    this.times = this.formBuilder.group({});
    this.times.addControl('times', this.formBuilder.array([]));
    const control = fg.controls['schedule'] as UntypedFormArray;
    control.push(this.formBuilder.group({
      days: this.buildDays()
    }));
  }

  buildDays() {
    const da = this.formBuilder.array([]);

    // tslint:disable-next-line: variable-name
    for (const _d of this.weekDays) {
      da.push(this.formBuilder.control(false));
    }
    return da;
  }

  public nav(url: string, params?: any) {
    this.queryParams[url] = params;
    // this.navCtrl.navigateRoot(url);
    this.router.navigateByUrl(url, { state: { urldata: params } }  )  ;
  }

  public navRoot(url: string, params?: any) {
    this.queryParams[url] = params;
    // this.navCtrl.navigateRoot(url);
    this.router.navigateByUrl(url, { state: { urldata: params } }  )  ;
  }

  public navForward(url: string, params?: any) {
    this.queryParams[url] = params;
    // this.navCtrl.navigateForward(url);
    this.router.navigateByUrl(url, { state: { urldata: params } }  )  ;
  }

  public navBack(url: string, params?: any) {
    this.queryParams[url] = params;
    // this.navCtrl.navigateBack(url);
    this.router.navigateByUrl(url, { state: { urldata: params } }  )  ;
  }

  public NavParams(s: any) {
    if (this.queryParams[s]) {
      if (s in this.queryParams) {
        return this.queryParams[s];
      } else {
        return null;
      }
    }
  }

  public getParams(url: any) {
    return this.queryParams[url];
  }

  public shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        console.log(key);
        return false;
      }
    }
    return true;
  }

  public deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      console.log('wrong key length');
      console.log(keys1); console.log(object1);
      console.log(keys2); console.log(object2);
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = typeof val1 === 'object' && typeof val2 === 'object';
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        console.log(key);
        return false;
      }
    }
    return true;
  }

}
