<div>
  <video #video id="video" width=320 height=180 autoplay class='video'></video>
</div>
<div>
  <button type='button' small ion-button id="snap" (click)="capture()" class='ion-float-left' >Snap Photo</button>
  <button type='button' small ion-button class='ion-float-left' (click)="cancelCapture()" class='ion-float-left' >Cancel</button>
</div>
<canvas #canvas id="canvas" width=320 height=180 class='canvas' ></canvas>
<br><br>
<ul class='images'>
  @for (c of captures; track c; let i = $index) {
    <li class='ion-float-left' >
      <img src="{{ c }}" class='image' (click)="photoSelected(i)" />
    </li>
  }
</ul>
