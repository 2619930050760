import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Br2nlPipe } from './br2nl/br2nl';
import { LimitLinesToPipe } from './limit-lines-to/limit-lines-to';
import { NoSanitizePipe } from './no-sanitize/no-sanitize';
import { Comma2nlPipe } from './comma2nl/comma2nl';
import { Comma2brPipe } from './comma2br/comma2br';
import { CommasAddSpacePipe } from './commas-add-space/comma-add-space.pipe';
import { Br2commaPipe } from './br2comma/br2comma';
import { Nl2brPipe } from './nl2br/nl2br';
import { ObjectKeysPipe } from './object-keys/object-keys.pipe';
import { CustomPipe } from './custom/custom.pipe';
import { CommaRemovePipe } from './comma-remove/comma-remove.pipe';

@NgModule({
    declarations: [
        Br2nlPipe,
        LimitLinesToPipe,
        NoSanitizePipe,
        Comma2nlPipe,
        Comma2brPipe,
        CommasAddSpacePipe,
        Br2commaPipe,
        Nl2brPipe,
        ObjectKeysPipe,
        CustomPipe,
        CommaRemovePipe,

    ],

    imports: [
        CommonModule
    ],

    exports: [
        Br2nlPipe,
        LimitLinesToPipe,
        NoSanitizePipe,
        Comma2nlPipe,
        Comma2brPipe,
        CommasAddSpacePipe,
        Br2commaPipe,
        Nl2brPipe,
        ObjectKeysPipe,
        CommaRemovePipe
    ]
})
export class PipesModule { }
