
<!-- <ion-row (click)="togglePopOver({ matchData: matchData}, $event)" [ngClass]="{'not-matched': matchData.percent<=80 ? true : false }" class='ion-text-wrap match-button' *ngIf="matchData.type === 'schedule' ">
<ion-col size='9' class='label ion-text-center'>
  <ion-icon name='star-outline' *ngIf="matchData.percent>80"></ion-icon>
  <ion-icon name='close-circle-outline' *ngIf="matchData.percent<=80"></ion-icon>
  <br>
    {{label}}
    <br>
      {{matchData.percent}}%
      <br>
      </ion-col>
      <ion-col class='ion-float-right'>
        <ion-icon name='ellipsis-horizontal-outline' class='more-icon ion-float-right'></ion-icon>
      </ion-col>
    </ion-row>


    <ion-list (click)="togglePopOver({ matchData: matchData}, $event)" class='ion-text-wrap match-button' *ngIf="matchData.type === 'schedule' ">
      <ion-item detail="true" class="label" [ngClass]="{'not-matched-label': matchData.percent<=80 ? true : false }  ">
        {{label}} (&nbsp; {{matchData.percent}}% Match &nbsp;)
      </ion-item>
    </ion-list>


    <ion-list (click)="togglePopOver({ matchData: matchData}, $event)"  class='ion-text-wrap' *ngIf="matchData.type !== 'schedule' ">
      <ion-item detail="true" class="label" [ngClass]="{'not-matched-label': matchData.match ? false : true }" >
        {{label}}
      </ion-item>
    </ion-list>


    -->

    @if (matchData.type === 'schedule' ) {
      <div (click)="togglePopOver({ matchData: matchData}, $event)" class='ion-text-wrap match-button'>
        <ion-item detail="true" class="label" [ngClass]="{'not-matched-label': matchData.percent<=80 ? true : false }  ">
          {{label}} (&nbsp; {{matchData.percent}}% Match &nbsp;)
        </ion-item>
      </div>
    }


    @if (matchData.type !== 'schedule' ) {
      <div (click)="togglePopOver({ matchData: matchData}, $event)"  class='ion-text-wrap'>
        <ion-item detail="true" class="label" [ngClass]="{'not-matched-label': matchData.match ? false : true }" >
          {{label}}
        </ion-item>
      </div>
    }

